import LeagueLogo from 'components/league-logo';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';
import leaguesDetails from 'constants/leagues-details';
import { LeagueType } from 'types';

import { SettingsNavigationContainer } from './styles';

type Props = {
    league: LeagueType;
    onChange: (league: LeagueType) => void;
};

export const SettingsNavigation = (props: Props) => (
    <SettingsNavigationContainer>
        <TabMenu>
            {leaguesDetails.map((details) => (
                <TabButton
                    active={props.league === details.league}
                    key={details.league}
                    onClick={() => props.onChange(details.league)}
                >
                    <div className="league-tab-item">
                        <div className="logo-container">
                            <LeagueLogo league={details.league} />
                        </div>
                        {details.nameAbbreviation}
                    </div>
                </TabButton>
            ))}
        </TabMenu>
    </SettingsNavigationContainer>
);
