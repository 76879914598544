import styled from '@emotion/styled';

export const TeamStatisticsTablesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
`;

export const TeamStatisticsListTableContainer = styled.div`
    max-width: 28rem;
`;
