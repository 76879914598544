import { useQuery } from 'react-query';

import * as Configuration from 'api/types/configuration';

import { SettingsKeys } from './queries';

const PROPERTIES: Configuration.Property[] = [
    {
        key: 'ncaamb_boxscore_player_assists',
        league: 'ncaamb',
        name: 'Assists',
        abbreviation: 'AST',
        description: 'Number of assists',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_assists_turnover_ratio',
        league: 'ncaamb',
        name: 'Assist to turnover ratio',
        abbreviation: 'AST/TO',
        description: 'Assist to turnover ratio',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_blocked_att',
        league: 'ncaamb',
        name: 'Blocked attempts',
        abbreviation: 'BLK ATT',
        description: 'Number of blocked attempts',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_blocks',
        league: 'ncaamb',
        name: 'Blocks',
        abbreviation: 'BLK',
        description: 'Number of shots blocked',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_defensive_rebounds',
        league: 'ncaamb',
        name: 'Defensive rebounds',
        abbreviation: 'DREB',
        description: 'Number of defensive rebounds',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_double_double',
        league: 'ncaamb',
        name: 'Double-double',
        abbreviation: 'DD',
        description: 'Signifies a double-double',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_ejections',
        league: 'ncaamb',
        name: 'Ejections',
        abbreviation: 'EJ',
        description: 'Number of ejections',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
    {
        key: 'ncaamb_boxscore_player_field_goals_att',
        league: 'ncaamb',
        name: 'Field goal attempts',
        abbreviation: 'FGA',
        description: 'Number of field goal attempts',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    },
];

const getProperties = async (): Promise<Configuration.Property[]> => {
    return new Promise((r) => setTimeout(r, 300)).then(() => PROPERTIES);
};

export function usePropertiesQuery(params: Configuration.GetPropertiesParams) {
    return useQuery({
        queryKey: SettingsKeys.getPropertiesByFilters(params),
        queryFn: () => getProperties(),
    });
}
