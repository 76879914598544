import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    Row,
    useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

import { LiveFlags } from 'api/types/live-flags';
import * as Table from 'components/table';
import useSorting from 'hooks/useSorting';
import { CompareFn } from 'types/sorting';

import { StatisticsEntity, StatisticsGridTableDefinition } from '../../types/statistics';
import { StatisticsCell } from '../statistics-cell';
import { StatisticsDetailsTooltip } from '../statistics-details-tooltip';
import { StatisticsNameCell } from '../statistics-name-cell';
import { TeamEmptyListMessage } from '../team-empty-list';

const sorting: Record<string, CompareFn<StatisticsEntity>> = {
    byEntityType: (a, b) => a.entityType.localeCompare(b.entityType),
    byName: (a, b) => a.entityName.localeCompare(b.entityName),
};

type Props = {
    tableDefinition: StatisticsGridTableDefinition;
    tableEntities: StatisticsEntity[];
    searchValue: string;
    expanded?: boolean;
    onIgnoreClick?: (id: string) => void;
};

export const TeamStatisticsGridTable = (props: Props) => {
    const sortTableEntities = useSorting(sorting.byEntityType, sorting.byName);
    const sortedTableEntities = sortTableEntities(props.tableEntities);

    const tableColumns = React.useMemo<ColumnDef<StatisticsEntity, any>[]>(
        () => [
            {
                id: 'name',
                accessorFn: (entity) => entity.displayName,
                header: () => <Table.TableCell />,
                cell: (context) => (
                    <Table.TableCell textAlign="left">
                        <StatisticsNameCell
                            context={
                                context.row.parentId ? undefined : context.row.original.entityType
                            }
                            fontWeight={
                                context.row.original.entityType === LiveFlags.Context.Team
                                    ? 'bold'
                                    : 'normal'
                            }
                        >
                            {context.getValue()}
                        </StatisticsNameCell>
                    </Table.TableCell>
                ),
            },
            ...props.tableDefinition.columns.map(
                (column): ColumnDef<StatisticsEntity, any> => ({
                    id: column.title,
                    enableGlobalFilter: false,
                    accessorFn: (entity) =>
                        column.keys[entity.entityType]
                            ? entity.properties[column.keys[entity.entityType]!]
                            : undefined,
                    header: () => (
                        <StatisticsDetailsTooltip
                            description={column.description}
                            name={column.name}
                            abbreviation={column.title}
                        >
                            <Table.TableCell textAlign="center">{column.title}</Table.TableCell>
                        </StatisticsDetailsTooltip>
                    ),
                    cell: (context) => (
                        <Table.TableCell textAlign="center">
                            <StatisticsCell
                                entityName={context.row.original.entityName}
                                propertyName={column.name}
                                property={context.getValue()}
                                onIgnoreClick={props.onIgnoreClick}
                            />
                        </Table.TableCell>
                    ),
                }),
            ),
        ],
        [props.tableDefinition.columns, props.onIgnoreClick],
    );

    const table = useReactTable<StatisticsEntity>({
        data: sortedTableEntities,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (entity) => entity.periods ?? [],
        globalFilterFn: (row: Row<StatisticsEntity>, _, filterValue: string) =>
            !!row.parentId ||
            row.original.entityType === LiveFlags.Context.Team ||
            row.original.entityName.toLowerCase().includes(filterValue.toLowerCase()),
        state: {
            globalFilter: props.searchValue,
            expanded: props.expanded ? true : undefined,
        },
    });

    if (!table.getRowModel().rows.length) {
        return <TeamEmptyListMessage />;
    }

    return (
        <Table.Table>
            <Table.TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Table.TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <React.Fragment key={header.id}>
                                {flexRender(header.column.columnDef.header, header.column)}
                            </React.Fragment>
                        ))}
                    </Table.TableRow>
                ))}
            </Table.TableHead>
            <Table.TableBody>
                {table.getRowModel().rows.map((row) => (
                    <Table.TableRow
                        key={row.id}
                        spacing={row.parentId ? 'dense' : 'default'}
                        variant={row.getIsExpanded() && !row.parentId ? 'highlight' : 'default'}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <React.Fragment key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </React.Fragment>
                        ))}
                    </Table.TableRow>
                ))}
            </Table.TableBody>
        </Table.Table>
    );
};
