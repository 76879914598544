import * as React from 'react';

import { TableRowContainer } from './styles';

type Props = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
> & {
    border?: 'default' | 'none';
    spacing?: 'default' | 'dense';
    variant?: 'default' | 'highlight';
};

export const TableRow = React.memo<Props>(
    ({ border = 'default', spacing = 'default', variant = 'default', ...props }) => (
        <TableRowContainer {...props} border={border} spacing={spacing} variant={variant} />
    ),
);
