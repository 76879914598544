import * as React from 'react';
import { Route } from 'type-route';
import { useDebounce } from 'usehooks-ts';

import * as Configuration from 'api/types/configuration';
import { routes } from 'app/router';
import Dropdown from 'components/dropdown';
import Loading from 'components/loading';
import { ViewContent } from 'components/view-content';
import ViewHeader from 'components/view-header';
import { usePropertyCategoryOptions } from 'hooks/options/usePropertyCategoryOptions';
import { usePropertyTypeOptions } from 'hooks/options/usePropertyTypeOptions';
import { usePropertiesQuery } from 'hooks/settings/usePropertiesQuery';
import { usePropertyCategoriesQuery } from 'hooks/settings/usePropertyCategoriesQuery';
import { useChangeParams } from 'hooks/useChangeParams';
import { Sorting } from 'types/sorting';

import { FormSearchField } from '../../components/form/form-search-field';
import { SettingsButton } from '../../components/settings-button';

import { PropertiesTable } from './components/properties-table';
import { SettingsNavigation } from './components/settings-navigation';
import { AdminSettingsViewContainer } from './styles';

type Props = {
    route: Route<typeof routes.adminSettings>;
};

export const SettingsView = (props: Props) => {
    const [searchValue, setSearchValue] = React.useState('');
    const searchValueDebounced = useDebounce(searchValue, 500);

    const propertyCategoriesQuery = usePropertyCategoriesQuery();
    const propertiesQuery = usePropertiesQuery({
        league: props.route.params.league,
        search: props.route.params.search,
        category: props.route.params.category,
        type: props.route.params.type,
        sortBy: props.route.params.sortBy,
        sortOrder: props.route.params.sortOrder,
    });

    const propertyTypeOptions = usePropertyTypeOptions(props.route.params.type);
    const propertyCategoriesOptions = usePropertyCategoryOptions(
        propertyCategoriesQuery.data,
        props.route.params.category,
    );

    const sorting =
        props.route.params.sortBy && props.route.params.sortOrder
            ? { attribute: props.route.params.sortBy, order: props.route.params.sortOrder }
            : undefined;

    const handleParamsChange = useChangeParams(props.route);

    const handleTypeChange = React.useCallback(
        (type?: Configuration.PropertyType) => handleParamsChange({ type }),
        [handleParamsChange],
    );
    const handleCategoryChange = React.useCallback(
        (category?: string) => handleParamsChange({ category }),
        [handleParamsChange],
    );
    const handleSortingChange = React.useCallback(
        (sorting?: Sorting<string>) =>
            handleParamsChange({ sortBy: sorting?.attribute, sortOrder: sorting?.order }),
        [handleParamsChange],
    );

    const handleCreatePropertyClick = React.useCallback(() => {
        routes.adminSettingsPropertyCreate({ league: props.route.params.league }).push();
    }, [props.route.params.league]);

    const handleEditPropertyClick = React.useCallback((property: Configuration.Property) => {
        routes.adminSettingsProperty({ league: property.league, key: property.key }).push();
    }, []);

    React.useEffect(() => {
        handleParamsChange({ search: searchValueDebounced });
    }, [handleParamsChange, searchValueDebounced]);

    return (
        <ViewContent>
            <AdminSettingsViewContainer>
                <ViewHeader iconFormat="inline" titleText="Admin Settings" />
                <SettingsNavigation
                    league={props.route.params.league}
                    onChange={(league) => routes.adminSettings({ league }).push()}
                />
                <div className="controls-container">
                    <div className="controls-group">
                        <SettingsButton onClick={handleCreatePropertyClick}>
                            + Add new property
                        </SettingsButton>
                        <Dropdown
                            label="Type"
                            onChange={handleTypeChange}
                            options={propertyTypeOptions}
                        />
                        <Dropdown
                            label="Category"
                            onChange={handleCategoryChange}
                            options={propertyCategoriesOptions}
                        />
                    </div>
                    <div className="controls-group">
                        <SettingsButton>Export</SettingsButton>
                        <FormSearchField
                            name="search-value"
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder="Search keyword"
                            value={searchValue}
                        />
                    </div>
                </div>
                {propertiesQuery.isLoading ? (
                    <Loading />
                ) : (
                    <PropertiesTable
                        onEditClick={handleEditPropertyClick}
                        onSortChange={handleSortingChange}
                        properties={propertiesQuery.data}
                        sorting={sorting}
                    />
                )}
            </AdminSettingsViewContainer>
        </ViewContent>
    );
};
