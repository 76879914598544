import { LeagueType } from 'types';

export enum PropertyType {
    GAME = 'game',
    PLAYER = 'player',
    TEAM = 'team',
}

export type PropertyCategory = {
    key: string;
    name: string;
};

export type PropertyToleranceStrategy = {
    name: 'tolerance';
    value: number;
};

export type PropertyRangeStrategy = {
    name: 'range';
    values: { from: number; to: number };
};

export type PropertyMappingStrategy = {
    name: 'mapping';
    values: { from: string[]; to: string }[];
};

export type PropertyStrategy =
    | PropertyToleranceStrategy
    | PropertyRangeStrategy
    | PropertyMappingStrategy;

export enum PropertyPriority {
    DEFAULT = 'default',
    LOW = 'low',
    HIGH = 'high',
}

export type PropertySourceConfiguration = {
    sourceId: string;
    enabled: boolean;
    priority: PropertyPriority;
    strategies: PropertyStrategy[];
};

export type Property = {
    key: string;
    league: LeagueType;
    name: string;
    abbreviation?: string;
    description?: string;
    type: PropertyType;
    category: PropertyCategory;
    enableForExtendedBoxscore?: boolean;
    excludeDivisions?: string;
    updatedAt: number;
    sourceConfiguration?: PropertySourceConfiguration[];
};

export type PropertyRequest = {
    key: string;
    league: LeagueType;
    name: string;
    abbreviation?: string;
    description?: string;
    type: PropertyType;
    category: string;
    enableForExtendedBoxscore?: boolean;
    excludeDivisions?: string;
    sourceConfiguration: PropertySourceConfiguration[];
};

export type GetPropertiesParams = {
    league: LeagueType;
    category?: string;
    type?: string;
    search?: string;
    sortBy?: string;
    sortOrder?: string;
};

export type GetPropertyParams = {
    league: LeagueType;
    key: string;
};

export type PostPropertyParams = PropertyRequest;
