import { useForm } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';
import Button from 'components/button';
import { Divider } from 'components/primitives/divider';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import * as Card from '../../card';
import { FormTextField } from '../../form/form-text-field';
import { CardProps } from '../types';

export const CardTolerance = (props: CardProps<Configuration.PropertyToleranceStrategy>) => {
    const form = useForm<Configuration.PropertyToleranceStrategy>({
        defaultValues: {
            name: 'tolerance',
            value: props.strategy?.value,
        },
    });

    return (
        <Card.Card size="medium">
            <Card.CardHeader
                title="Tolerance"
                subtitle={`Source: ${sourcesDetails[props.source as SourceId]?.label}`}
            />
            <Card.CardContent>
                <FormTextField {...form.register('value')} label="Tolerance" type="number" />
            </Card.CardContent>
            <Divider />
            <Card.CardActions>
                {props.strategy && (
                    <Card.CardActionSecondary>
                        <Button variant="error" onClick={() => props.onSubmit(null)}>
                            Delete all
                        </Button>
                    </Card.CardActionSecondary>
                )}
                <Button variant="variant6-no-border" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    variant="variant6"
                    onClick={form.handleSubmit((data) => props.onSubmit(data))}
                >
                    Insert
                </Button>
            </Card.CardActions>
        </Card.Card>
    );
};
