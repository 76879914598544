import * as Configuration from 'api/types/configuration';

export const SettingsKeys = {
    getProperties: ({ league, ...params }: Partial<Configuration.GetPropertiesParams>) => {
        return [league, 'properties', params];
    },
    getPropertiesByFilters: ({ league, ...params }: Configuration.GetPropertiesParams) => {
        return [league, 'properties', params];
    },
    getProperty: ({ league, key }: Configuration.GetPropertyParams) => {
        return [league, 'properties', key];
    },
    getPropertyCategories: () => {
        return ['property-categories'];
    },
};
