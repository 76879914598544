import * as React from 'react';

import Tooltip from 'components/primitives/tooltip';

import { StatisticsDetailsTooltipContainer } from './styles';

type Props = {
    abbreviation?: string;
    children: React.ComponentProps<typeof Tooltip>['children'];
    description?: string;
    name: string;
};

export const StatisticsDetailsTooltip = React.memo<Props>((props) => (
    <Tooltip
        disableInteractive
        title={
            <StatisticsDetailsTooltipContainer>
                <span>
                    <span>{props.name}</span>
                    {props.abbreviation && <span> ({props.abbreviation})</span>}
                </span>
                {props.description && <span className="description">{props.description}</span>}
            </StatisticsDetailsTooltipContainer>
        }
    >
        {props.children}
    </Tooltip>
));
