import * as React from 'react';

import { TableContext } from '../contexts/table';

import { TableBodyContainer } from './styles';

type Props = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
> & {
    size?: 'medium' | 'large';
};

export const TableBody = React.memo<Props>(({ children, size = 'medium', ...props }) => (
    <TableBodyContainer {...props} size={size}>
        <TableContext.Provider value={{ variant: 'body' }}>{children}</TableContext.Provider>
    </TableBodyContainer>
));
