import { LiveFlags } from 'api/types/live-flags';

type Config = {
    defaultLimit: number;
    allowedLimits: number[];
};

const defaultAllowedLimits = [50, 100, 200];

export const STATUS_LIMIT_CONFIG: Record<LiveFlags.FlagStatus, Config> = {
    [LiveFlags.FlagStatus.AUTOMATICALLY_RESOLVED]: {
        defaultLimit: 500,
        allowedLimits: [500],
    },
    [LiveFlags.FlagStatus.CREATED]: {
        defaultLimit: 5000,
        allowedLimits: [5000],
    },
    [LiveFlags.FlagStatus.IGNORED]: {
        defaultLimit: 50,
        allowedLimits: defaultAllowedLimits,
    },
    [LiveFlags.FlagStatus.RESOLVED]: {
        defaultLimit: 50,
        allowedLimits: defaultAllowedLimits,
    },
};

export const isInLimits = (status: LiveFlags.FlagStatus, limit: number) =>
    STATUS_LIMIT_CONFIG[status].allowedLimits.includes(limit);
