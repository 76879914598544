import * as React from 'react';

import { LiveFlags } from 'api/types/live-flags';
import * as Table from 'components/table';

import { StatisticsEntity, StatisticsListTableDefinition } from '../../types/statistics';
import { StatisticsCell } from '../statistics-cell';
import { StatisticsDetailsTooltip } from '../statistics-details-tooltip';
import { StatisticsNameCell } from '../statistics-name-cell';

import { TeamStatisticsListTableContainer } from './styles';

type Props = {
    tableDefinition: StatisticsListTableDefinition;
    tableEntities: StatisticsEntity[];
    teamName?: string;
    onIgnoreClick?: (id: string) => void;
    onUnignoreClick?: (id: string) => void;
};

export const TeamStatisticsListTable = (props: Props) => {
    const teamEntity = React.useMemo<StatisticsEntity | undefined>(
        () => props.tableEntities.find((entity) => entity.entityType === LiveFlags.Context.Team),
        [props.tableEntities],
    );

    return (
        <TeamStatisticsListTableContainer>
            <Table.Table>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell textAlign="left">
                            {props.tableDefinition.title}
                        </Table.TableCell>
                        <Table.TableCell />
                    </Table.TableRow>
                </Table.TableHead>
                <Table.TableBody>
                    {props.tableDefinition.rows.map((row) => (
                        <Table.TableRow key={row.title}>
                            <StatisticsDetailsTooltip
                                description={row.description}
                                name={row.title}
                            >
                                <Table.TableCell textAlign="left">
                                    <StatisticsNameCell context="team">
                                        {row.title}
                                    </StatisticsNameCell>
                                </Table.TableCell>
                            </StatisticsDetailsTooltip>
                            <Table.TableCell textAlign="center">
                                <StatisticsCell
                                    entityName={props.teamName}
                                    propertyName={row.title}
                                    property={teamEntity?.properties[row.key]}
                                    onIgnoreClick={props.onIgnoreClick}
                                    onUnignoreClick={props.onUnignoreClick}
                                />
                            </Table.TableCell>
                        </Table.TableRow>
                    ))}
                </Table.TableBody>
            </Table.Table>
        </TeamStatisticsListTableContainer>
    );
};
