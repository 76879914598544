import styled from '@emotion/styled';

export const LeagueItemContainer = styled.div`
    min-height: 17rem;

    a {
        text-decoration: none;
    }

    /* Changes opacity of all children but .admin-link, if not .admin-link is hovered. */
    .league-item:hover:not(:has(.admin-link:hover)) > *:not(.admin-link),
    .admin-link:hover {
        opacity: 0.7;
        transition: 0.15s ease-in-out;
    }

    & > .league-item-header {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        border-bottom: 0.0625rem solid var(--color-home-league-border);
        min-height: 2.5rem;
        padding: 0 0.5rem;

        & > .main-link {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            flex-grow: 1;

            & > .logo-container {
                width: 1.625rem;
                height: 1.625rem;
            }

            & > .title {
                font-size: 1rem;
                font-weight: 700;
                color: var(--color-text);
            }
        }
    }

    & > .link-list {
        list-style: none;
        margin: 0 0 0 3rem;
        padding: 0;

        & > .link-item a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 0.0625rem solid var(--color-home-league-border);
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1rem;
            color: var(--color-text-link);

            & > .link-icon-container {
                width: 1rem;
                height: 1rem;
            }
        }
    }
`;
