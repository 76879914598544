import styled from '@emotion/styled';

export const FormSearchField = styled.input`
    outline: none;
    border: 0.0625rem solid var(--color-borderline);
    border-radius: 0.2rem;
    min-height: 2rem;
    background: var(--color-background);
    padding: 0.5rem;
    color: var(--color-text);
    line-height: 1rem;
`;
