import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import * as React from 'react';

import { CheckboxIcon } from 'components/primitives/checkbox';

type Props = Omit<React.ComponentProps<typeof FormControlLabel>, 'control'> & {
    label?: React.ReactNode;
};

const FormCheckboxInternal = React.forwardRef<typeof FormControlLabel, Props>((props, ref) => (
    <FormControlLabel
        {...props}
        ref={ref}
        control={
            <Checkbox
                disableRipple
                checkedIcon={<CheckboxIcon checked />}
                icon={<CheckboxIcon />}
            />
        }
    />
));

export const FormCheckbox = styled(FormCheckboxInternal)`
    & .${formControlLabelClasses.label} {
        font-weight: 400;
        font-size: 0.875rem;
    }
`;
