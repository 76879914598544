import { styled, svgIconClasses } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Select, { selectClasses, SelectProps } from '@mui/material/Select';
import * as React from 'react';

import * as Configuration from 'api/types/configuration';

const PropertyPrioritySelectInternal = React.forwardRef<typeof Select, SelectProps>(
    (props, ref) => (
        <FormControl>
            <Select {...props} ref={ref}>
                {Object.values(Configuration.PropertyPriority).map((priority) => (
                    <MenuItem value={priority} key={priority}>
                        {priority}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    ),
);

export const PropertyPrioritySelect = styled(PropertyPrioritySelectInternal)`
    & .${selectClasses.select}.${selectClasses.outlined} {
        box-sizing: border-box;
        height: 1.375rem;
        width: 4.0625rem;
        padding: 0.25rem;
        color: var(--color-text);
        font-size: 0.75rem;
        line-height: 100%;
        text-transform: capitalize;
    }

    .${outlinedInputClasses.notchedOutline}, & .${outlinedInputClasses.notchedOutline} {
        border-color: var(--color-borderline) !important;
        border-width: 0.0625rem !important;
    }

    & .${svgIconClasses.root} {
        right: 0rem;
        color: var(--color-property-priority-select-icon);
    }
`;
