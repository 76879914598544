import { useForm } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';
import Button from 'components/button';
import { Divider } from 'components/primitives/divider';
import { LeagueType, SourceId } from 'types';

import { SectionCategories } from './section-categories';
import { SectionDetails } from './section-details';
import { SectionSources } from './section-sources';
import { PropertyFormContainer } from './styles';

type Props = {
    categories: Configuration.PropertyCategory[];
    isSubmitting?: boolean;
    league: LeagueType;
    onCancel: () => void;
    onSubmit: (property: Configuration.PropertyRequest) => void;
    property?: Configuration.Property;
    sources: SourceId[];
    title: string;
};

export const PropertyForm = (props: Props) => {
    const form = useForm<Configuration.PropertyRequest>({
        values: {
            ...(props.property ?? {}),
            key: props.property?.key ?? '',
            name: props.property?.name ?? '',
            league: props.league,
            type: props.property?.type ?? Configuration.PropertyType.PLAYER,
            category: props.property?.category?.key ?? '',
            sourceConfiguration: Object.values(SourceId)
                .filter((source) => source !== SourceId.SR)
                .map(
                    (sourceId) =>
                        props.property?.sourceConfiguration?.find(
                            (config) => config.sourceId === sourceId,
                        ) ?? {
                            sourceId,
                            enabled: false,
                            priority: Configuration.PropertyPriority.DEFAULT,
                            strategies: [],
                        },
                ),
        },
    });

    return (
        <PropertyFormContainer>
            <h1 className="form-title">{props.title}</h1>
            <div className="form-sections-container">
                <SectionDetails form={form} property={props.property} />
                <SectionSources form={form} />
                <SectionCategories categories={props.categories} form={form} />
            </div>
            <div>
                <Divider />
                <div className="form-controls">
                    <Button
                        disabled={props.isSubmitting}
                        onClick={props.onCancel}
                        variant="variant6"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={props.isSubmitting}
                        onClick={form.handleSubmit(props.onSubmit)}
                        variant="variant6"
                    >
                        {props.isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                </div>
            </div>
        </PropertyFormContainer>
    );
};
