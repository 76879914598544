import * as React from 'react';

import * as Configuration from 'api/types/configuration';
import { IconDecorated } from 'components/icons';
import Loading from 'components/loading';
import * as Table from 'components/table';
import { sourcesDetails } from 'constants/sources-details';
import { usePropertyQuery } from 'hooks/settings/usePropertyQuery';
import { SourceId } from 'types';

import { PropertyPopoverContainer } from './styles';

type Props = {
    property: Configuration.Property;
};

export const PropertyPopover = (props: Props) => {
    const propertyQuery = usePropertyQuery({
        league: props.property.league,
        key: props.property.key,
    });

    const sourceConfiguration = React.useMemo(() => {
        return Object.values(SourceId)
            .filter((source) => source !== SourceId.SR)
            .map(
                (sourceId) =>
                    propertyQuery.data?.sourceConfiguration?.find(
                        (config) => config.sourceId === sourceId,
                    ) ?? {
                        sourceId,
                        enabled: false,
                        priority: Configuration.PropertyPriority.DEFAULT,
                        strategies: [],
                    },
            );
    }, [propertyQuery.data]);

    return (
        <PropertyPopoverContainer>
            <div className="property-header">
                <span className="property-name">{props.property.name}</span>
                <span className="property-key">Key: {props.property.key}</span>
            </div>
            {propertyQuery.data ? (
                <Table.Table>
                    <Table.TableHead>
                        <Table.TableRow border="none">
                            <Table.TableCell colSpan={3} />
                            <Table.TableCell colSpan={3} textAlign="center">
                                Comparison Strategy
                            </Table.TableCell>
                        </Table.TableRow>
                        <Table.TableRow>
                            <Table.TableCell textAlign="left">Name</Table.TableCell>
                            <Table.TableCell>Enable</Table.TableCell>
                            <Table.TableCell textAlign="left">Priority</Table.TableCell>
                            <Table.TableCell>Priority</Table.TableCell>
                            <Table.TableCell>Tolerance</Table.TableCell>
                            <Table.TableCell>Range</Table.TableCell>
                        </Table.TableRow>
                    </Table.TableHead>
                    <Table.TableBody size="large">
                        {sourceConfiguration.map((config) => (
                            <Table.TableRow key={config.sourceId}>
                                <Table.TableCell textAlign="left">
                                    {sourcesDetails[config.sourceId as SourceId].label}
                                </Table.TableCell>
                                <Table.TableCell>
                                    {config.enabled && (
                                        <div className="icon-container">
                                            <IconDecorated name="CheckRounded" sizeRem={1} />
                                        </div>
                                    )}
                                </Table.TableCell>
                                <Table.TableCell className="priority-container" textAlign="left">
                                    {config.priority}
                                </Table.TableCell>
                                {['tolerance', 'range', 'mapping'].map((name) => (
                                    <Table.TableCell key={name}>
                                        {config.strategies.some((s) => s.name === name) && (
                                            <div className="icon-container">
                                                <IconDecorated name="CheckRounded" sizeRem={1} />
                                            </div>
                                        )}
                                    </Table.TableCell>
                                ))}
                            </Table.TableRow>
                        ))}
                    </Table.TableBody>
                </Table.Table>
            ) : (
                <Loading />
            )}
        </PropertyPopoverContainer>
    );
};
