import styled from '@emotion/styled';

export const LiveGameTableSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2.8125rem;
`;

export const LiveGameHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    & .controls-primary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
    }

    & > .controls-secondary {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;
