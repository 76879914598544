import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as React from 'react';

import { LiveFlags } from 'api/types/live-flags';
import ActionButton from 'components/action-button';
import useDateSnapshot from 'hooks/useDateSnapshot';

import useTimeDifference from '../../hooks/useTimeDifference';
import { StatisticsEntityProperty } from '../../types/statistics';

import { StatisticsCellPopoverContainer } from './styles';

dayjs.extend(relativeTime);

type Props = {
    entityName?: string;
    propertyName: string;
    property: StatisticsEntityProperty;
    onIgnoreClick?: (id: string) => void;
    onUnignoreClick?: (id: string) => void;
};

export const StatisticsCellPopover = ({ entityName = 'Unknown', ...props }: Props) => {
    const difference = useTimeDifference(props.property.createdAt);
    const dateSnapshot = useDateSnapshot(props.property.createdAt);
    const [differenceLabel, setDifferenceLabel] = React.useState('Just now');

    React.useEffect(() => {
        if (Math.floor(difference / 1000) > 45) {
            setDifferenceLabel(dayjs(props.property.createdAt).fromNow());
        }
    }, [props.property.createdAt, difference]);

    const displayValue =
        props.property.status === LiveFlags.FlagStatus.IGNORED
            ? 'Ignored'
            : `${props.property.internalValue ?? '-'} / ${props.property.externalValue ?? '-'}`;

    return (
        <StatisticsCellPopoverContainer>
            <div className="group-list">
                <div className="group">
                    <span className="group-item">
                        {props.propertyName}: {displayValue}
                    </span>
                    <span className="group-item">{entityName}</span>
                </div>
                <div className="group">
                    <span className="group-item">Flagged: {differenceLabel}</span>
                    <span className="group-item">{dateSnapshot}</span>
                </div>
            </div>
            {props.property.status === LiveFlags.FlagStatus.IGNORED && props.onUnignoreClick && (
                <ActionButton
                    content="Unignore"
                    onClick={() => props.onUnignoreClick?.(props.property.id)}
                    variant="default"
                />
            )}
            {props.property.status === LiveFlags.FlagStatus.CREATED && props.onIgnoreClick && (
                <ActionButton
                    content="Ignore"
                    iconName="IgnoredFlag"
                    onClick={() => props.onIgnoreClick?.(props.property.id)}
                    variant="default"
                />
            )}
        </StatisticsCellPopoverContainer>
    );
};
