import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';
import { CheckboxIcon } from 'components/primitives/checkbox';
import * as Table from 'components/table';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import { PropertyPrioritySelect } from '../property-priority-select';
import { ModalAction, PropertyStrategyModal } from '../property-strategy-modal';

import { SectionSourcesContainer, SectionTitle } from './styles';

type Props = {
    form: UseFormReturn<Configuration.PropertyRequest>;
};

export const SectionSources = (props: Props) => {
    const [action, setAction] = React.useState<ModalAction>();

    const formSources = useFieldArray({
        name: 'sourceConfiguration',
        control: props.form.control,
    });

    const handleSubmit = (newStrategy: Configuration.PropertyStrategy | null) => {
        const sourceConfigEntry = Array.from(
            props.form.getValues().sourceConfiguration.entries(),
        ).find(([_, sourceConfiguration]) => sourceConfiguration.sourceId === action?.source);

        if (!sourceConfigEntry) {
            return setAction(undefined);
        }

        const [sourceConfigIndex, sourceConfig] = sourceConfigEntry;
        const doesStrategyExist = sourceConfig.strategies.some((s) => s.name === action?.name);

        if (doesStrategyExist && !newStrategy) {
            setAction(undefined);
            formSources.update(sourceConfigIndex, {
                ...sourceConfig,
                strategies: sourceConfig.strategies.filter((s) => s.name !== action?.name),
            });
        }

        if (doesStrategyExist && newStrategy) {
            setAction(undefined);
            formSources.update(sourceConfigIndex, {
                ...sourceConfig,
                strategies: sourceConfig.strategies.map((s) => {
                    return s.name === newStrategy.name ? newStrategy : s;
                }),
            });
        }

        if (!doesStrategyExist && newStrategy) {
            setAction(undefined);
            formSources.update(sourceConfigIndex, {
                ...sourceConfig,
                strategies: [...sourceConfig.strategies, newStrategy],
            });
        }
    };

    return (
        <React.Fragment>
            <PropertyStrategyModal
                action={action}
                onClose={() => setAction(undefined)}
                onSubmit={handleSubmit}
            />
            <SectionSourcesContainer>
                <SectionTitle>Sources</SectionTitle>
                <div className="table-container">
                    <Table.Table>
                        <Table.TableHead>
                            <Table.TableRow border="none">
                                <Table.TableCell colSpan={3} />
                                <Table.TableCell colSpan={3} textAlign="center">
                                    Comparison Strategy
                                </Table.TableCell>
                            </Table.TableRow>
                            <Table.TableRow>
                                <Table.TableCell textAlign="left">Name</Table.TableCell>
                                <Table.TableCell>Enable</Table.TableCell>
                                <Table.TableCell>Priority</Table.TableCell>
                                <Table.TableCell>Tolerance</Table.TableCell>
                                <Table.TableCell>Range</Table.TableCell>
                                <Table.TableCell>Mapping</Table.TableCell>
                            </Table.TableRow>
                        </Table.TableHead>
                        <Table.TableBody size="large">
                            {formSources.fields.map((field, index) => (
                                <Table.TableRow key={field.sourceId}>
                                    <Table.TableCell textAlign="left">
                                        {sourcesDetails[field.sourceId as SourceId].label}
                                    </Table.TableCell>
                                    <Table.TableCell className="enabled-cell">
                                        <Controller
                                            control={props.form.control}
                                            name={`sourceConfiguration.${index}.enabled`}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    disableRipple
                                                    checkedIcon={<CheckboxIcon checked />}
                                                    icon={<CheckboxIcon />}
                                                    sx={{ padding: 0 }}
                                                />
                                            )}
                                        />
                                    </Table.TableCell>
                                    <Table.TableCell textAlign="left">
                                        <Controller
                                            name={`sourceConfiguration.${index}.priority`}
                                            control={props.form.control}
                                            render={({ field }) => (
                                                <PropertyPrioritySelect {...field} />
                                            )}
                                        />
                                    </Table.TableCell>
                                    {['tolerance', 'range', 'mapping'].map((name) => {
                                        const strategy = field.strategies.find(
                                            (s) => s.name === name,
                                        );

                                        return (
                                            <Table.TableCell
                                                className="tolerance-cell"
                                                key={name}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setAction({
                                                        source: field.sourceId,
                                                        name,
                                                        strategy,
                                                    } as ModalAction);
                                                }}
                                            >
                                                {strategy ? 'Edit' : '+'}
                                            </Table.TableCell>
                                        );
                                    })}
                                </Table.TableRow>
                            ))}
                        </Table.TableBody>
                    </Table.Table>
                </div>
            </SectionSourcesContainer>
        </React.Fragment>
    );
};
