import styled from '@emotion/styled';

export const ViewContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    background: var(--bg-content-top-right);
    background-size: 10%;
`;
