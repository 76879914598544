import { useQuery } from 'react-query';

import * as Configuration from 'api/types/configuration';

import { SettingsKeys } from './queries';

const CATEGORIES: Configuration.PropertyCategory[] = [
    { key: 'boxscore', name: 'Boxscore' },
    { key: 'polls', name: 'Polls' },
    { key: 'roster', name: 'Roster' },
    { key: 'schedule', name: 'Schedule' },
    { key: 'seasonal', name: 'Seasonal' },
    { key: 'standings', name: 'Standings' },
];

const getPropertyCategories = async (): Promise<Configuration.PropertyCategory[]> => {
    return new Promise((r) => setTimeout(r, 3000)).then(() => CATEGORIES);
};

export function usePropertyCategoriesQuery() {
    return useQuery({
        queryKey: SettingsKeys.getPropertyCategories(),
        queryFn: () => getPropertyCategories(),
    });
}
