import styled from '@emotion/styled';

export const StatisticsDetailsTooltipContainer = styled.div`
    color: var(--color-text);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;

    & > .description {
        display: block;
        opacity: 0.6;
    }
`;
