import styled from '@emotion/styled';

export const PropertyTypeLabel = styled.span`
    text-transform: capitalize;
`;

export const PropertyTableContainer = styled.div`
    .sortable-cell {
        cursor: pointer;

        .content {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            gap: 0.25rem;

            svg {
                margin: -1rem 0;
                width: 1.125rem;
                height: 1.125rem;
            }
        }
    }

    .type-cell {
        text-transform: capitalize;
    }

    .details-button {
        text-decoration: underline;
        cursor: pointer;
    }

    .button-link {
        border: none;
        background: none;
        padding: 0;
        color: var(--color-text-link);
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
    }
`;
