import styled from '@emotion/styled';

export const SettingsNavigationContainer = styled.div`
    & .league-tab-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.5rem;

        & > .logo-container {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
`;
