import styled from '@emotion/styled';

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 0.625rem;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
`;

export const CheckboxIconContainer = styled.div`
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-checked='true'] {
        border-radius: 20%;
        background-color: var(--color-checkbox-checked-background);

        .icon-container {
            width: 1rem;
            height: 1rem;
            color: var(--color-checkbox-checked-icon);
            transform: scale(0.75);
        }
    }

    &[data-checked='false'] {
        border: 0.0625rem solid var(--color-checkbox-unchecked-border);
        background-color: transparent;
    }
`;
