import styled from '@emotion/styled';

export const TableContainer = styled.table`
    display: table;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0rem;
`;

export const TableHeadContainer = styled.thead`
    border-bottom: 0.125rem solid var(--color-table-header-border);
`;

export const TableBodyContainer = styled.tbody<{ size: 'medium' | 'large' }>`
    font-size: ${(props) => (props.size === 'medium' ? '0.75rem' : '0.875rem')};
    line-height: 0.875rem;
`;

export const TableRowContainer = styled.tr<{
    border: 'default' | 'none';
    spacing: 'default' | 'dense';
    variant: 'default' | 'highlight';
}>`
    --table-row-spacing: ${(props) => (props.spacing === 'default' ? '0.5rem' : '0.25rem 0.5rem')};

    border-bottom: 0.0625rem solid
        ${(props) => (props.border === 'default' ? 'var(--color-table-row-border)' : 'transparent')};
    background-color: ${(props) =>
        props.variant === 'highlight' ? 'var(--color-table-row-highlight-bg)' : 'transparent'};
`;

export const TableCellContainer = styled.td<{
    textAlign: 'center' | 'inherit' | 'justify' | 'left' | 'right';
}>`
    padding: var(--table-row-spacing);
    color: var(--color-table-cell-text);
    text-align: ${(props) => props.textAlign};
    font-weight: 400;
`;

export const TableCellHeadContainer = styled(TableCellContainer.withComponent('th'))`
    color: var(--color-table-header-text);
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.75rem;
    text-transform: uppercase;
`;
