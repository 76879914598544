import { IconDecorated } from 'components/icons';

import { StatisticsNameCellContainer } from './styles';

type Props = {
    children?: React.ReactNode;
    fontWeight?: 'normal' | 'bold';
    context?: 'team' | 'player';
};

export const StatisticsNameCell = ({ children, fontWeight = 'normal', context }: Props) => {
    const playerIcon = <IconDecorated paletteColor="purple" sizeRem={1} name="PersonRounded" />;
    const teamIcon = <IconDecorated paletteColor="blue400" sizeRem={1} name="GroupWorkRounded" />;

    return (
        <StatisticsNameCellContainer fontWeight={fontWeight}>
            <div className="icon-container">
                {context === 'player' && playerIcon}
                {context === 'team' && teamIcon}
            </div>
            <div className="content-container">{children}</div>
        </StatisticsNameCellContainer>
    );
};
