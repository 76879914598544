import * as Configuration from 'api/types/configuration';
import Modal from 'components/modal';

import { CardMapping } from './card-mapping';
import { CardRange } from './card-range';
import { CardTolerance } from './card-tolerance';

export type ModalAction =
    | {
          source: string;
          name: Configuration.PropertyToleranceStrategy['name'];
          strategy?: Configuration.PropertyToleranceStrategy;
      }
    | {
          source: string;
          name: Configuration.PropertyMappingStrategy['name'];
          strategy?: Configuration.PropertyMappingStrategy;
      }
    | {
          source: string;
          name: Configuration.PropertyRangeStrategy['name'];
          strategy?: Configuration.PropertyRangeStrategy;
      };

type Props = {
    onClose: () => void;
    onSubmit: (strategy: Configuration.PropertyStrategy | null) => void;
    action?: ModalAction;
};

export const PropertyStrategyModal = (props: Props) => (
    <Modal open={!!props.action}>
        {props.action?.name === 'mapping' && (
            <CardMapping
                onClose={props.onClose}
                onSubmit={props.onSubmit}
                source={props.action.source}
                strategy={props.action.strategy}
            />
        )}
        {props.action?.name === 'tolerance' && (
            <CardTolerance
                onClose={props.onClose}
                onSubmit={props.onSubmit}
                source={props.action.source}
                strategy={props.action.strategy}
            />
        )}
        {props.action?.name === 'range' && (
            <CardRange
                onClose={props.onClose}
                onSubmit={props.onSubmit}
                source={props.action.source}
                strategy={props.action.strategy}
            />
        )}
    </Modal>
);
