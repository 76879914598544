import dayjs from 'dayjs';

import { LiveFlags } from 'api/types/live-flags';

export type StatisticsGridTableDefinition = {
    title: string;
    tip?: string;
    type: 'grid';
    columns: {
        title: string;
        name: string;
        description?: string;
        keys: { player?: string; team?: string };
    }[];
};

export type StatisticsListTableDefinition = {
    title: string;
    tip?: string;
    type: 'list';
    rows: {
        title: string;
        description?: string;
        key: string;
    }[];
};

export type StatisticsTableDefinition =
    | StatisticsGridTableDefinition
    | StatisticsListTableDefinition;

export type StatisticsEntityProperty = {
    id: string;
    status: LiveFlags.FlagStatus;
    internalValue: string | number | null;
    externalValue: string | number | null;
    createdAt: dayjs.Dayjs;
};

export type StatisticsEntity = {
    entityId: string;
    entityName: string;
    entityType: LiveFlags.Context;
    displayName: string;
    qualifier: 'away' | 'home';
    properties: Partial<Record<PropertyKey, StatisticsEntityProperty>>;
    periods?: StatisticsEntity[];
};

export enum StatisticsPriority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}
