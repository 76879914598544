import * as React from 'react';

import { LeagueType } from 'types';

import { TABLE_DEFINITIONS_NCAAMB_NCAAWB } from '../constants/table-definitions-ncaamb-ncaawb';
import { StatisticsTableDefinition } from '../types/statistics';

export const useStatisticsTableDefinitions = (league: LeagueType): StatisticsTableDefinition[] => {
    return React.useMemo(() => {
        switch (league) {
            case 'ncaamb':
            case 'ncaawb':
                return TABLE_DEFINITIONS_NCAAMB_NCAAWB;
            default:
                return [];
        }
    }, [league]);
};
