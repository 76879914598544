import { Route } from 'type-route';

import { groups } from 'app/router';

import { ViewContentContainer } from './styles';
import { PropertyCreateView } from './views/property-create';
import { PropertyEditView } from './views/property-edit';
import { SettingsView } from './views/settings';

type Props = {
    route: Route<typeof groups.adminSettings>;
};

export const AdminSettingsRoute = ({ route }: Props) => {
    return (
        <ViewContentContainer>
            {route.name === 'adminSettings' && <SettingsView route={route} />}
            {route.name === 'adminSettingsProperty' && <PropertyEditView route={route} />}
            {route.name === 'adminSettingsPropertyCreate' && <PropertyCreateView route={route} />}
        </ViewContentContainer>
    );
};
