import styled from '@emotion/styled';

export const PropertyPopoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    border: 0.0625rem solid var(--color-borderline);
    box-shadow: 0 0.125rem 0.25rem 0 var(--blue2000);
    padding: 0.75rem 1rem;
    background-color: var(--color-background);
    text-align: center;

    .property-header {
        text-align: left;

        .property-name,
        .property-key {
            display: block;
            color: var(--color-text);
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 700;
        }

        .property-key {
            color: rgba(var(--color-text-rgb), 0.6);
        }
    }

    .icon-container {
        display: inline-block;
    }

    .priority-container {
        text-transform: capitalize;
    }
`;
