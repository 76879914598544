import * as React from 'react';

import { TableContext } from '../contexts/table';

import { TableHeadContainer } from './styles';

type Props = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
>;

export const TableHead = React.memo<Props>(({ children, ...props }) => (
    <TableHeadContainer {...props}>
        <TableContext.Provider value={{ variant: 'head' }}>{children}</TableContext.Provider>
    </TableHeadContainer>
));
