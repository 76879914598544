import dayjs from 'dayjs';
import * as React from 'react';

function useTimeDifference(initial: dayjs.Dayjs): number {
    const [difference, setDifference] = React.useState(0);

    const handleClockTick = React.useCallback(() => {
        setDifference(dayjs().diff(initial, 'milliseconds'));
    }, [initial]);

    React.useEffect(handleClockTick, [handleClockTick]);

    React.useEffect(() => {
        const interval = setInterval(handleClockTick, 1000);

        return () => clearInterval(interval);
    }, [handleClockTick]);

    return difference;
}

export default useTimeDifference;
