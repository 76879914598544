import * as React from 'react';

import {
    BasicButton,
    DarkPrimary,
    ErrorButton,
    Variant5,
    Variant6,
    Variant6NoBorder,
} from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

interface ButtonFactoryProps extends ButtonProps {
    children?: React.ReactNode;
    variant: 'basic' | 'dark-primary' | 'variant5' | 'variant6' | 'variant6-no-border' | 'error';
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonFactoryProps>>(
    ({ variant, ...rest }, ref) => {
        switch (variant) {
            case 'basic':
                return <BasicButton ref={ref} {...rest} />;
            case 'dark-primary':
                return <DarkPrimary ref={ref} {...rest} />;
            case 'variant5':
                return <Variant5 ref={ref} {...rest} />;
            case 'variant6':
                return <Variant6 ref={ref} {...rest} />;
            case 'variant6-no-border':
                return <Variant6NoBorder ref={ref} {...rest} />;
            case 'error':
                return <ErrorButton ref={ref} {...rest} />;
            default:
                throw new Error('This variant is not supported');
        }
    },
);

export default Button;
