import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { typographyClasses } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const TextFieldInternal = React.forwardRef<any, TextFieldProps>((props, ref) => (
    <TextField {...props} ref={ref} focused={!props.disabled} />
));

export const FormTextField = styled(TextFieldInternal)`
    flex-grow: 1;

    & .${inputLabelClasses.root} {
        color: var(--color-text);
        font-weight: 400;
        font-size: 1rem;
    }

    & .${inputLabelClasses.root}.Mui-disabled {
        max-width: calc(133% - 32px);
        background-color: var(--color-background);
        left: -0.25rem;
        padding: 0 0.5rem;
        transform: translate(14px, -9px) scale(0.75);
    }

    & .Mui-disabled .${outlinedInputClasses.notchedOutline} {
        /* background-color: #dae4ed; */
        border-color: #b2c6d9;
    }

    & .Mui-focused .${outlinedInputClasses.notchedOutline} {
        border-color: var(--blue500) !important;
        border-width: 0.0625rem !important;
    }

    * .${inputAdornmentClasses.root} .${typographyClasses.root} {
        color: var(--color-text);
        opacity: 0.5;
    }

    & .${inputBaseClasses.root} .${inputBaseClasses.input} {
        box-sizing: border-box;
        height: 3rem;
        padding: 0.5rem 1rem;
        color: var(--color-text);
    }
`;
