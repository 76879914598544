import {
    ColumnDef,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    OnChangeFn,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import * as React from 'react';

import * as Configuration from 'api/types/configuration';
import { Icon } from 'components/icons';
import Popover from 'components/popover';
import * as Table from 'components/table';
import { Order, Sorting } from 'types/sorting';

import { PropertyPopover } from '../property-popover';

import { PropertyTableContainer } from './styles';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const SortingUtils = {
    toSortingState: (sorting?: Sorting<string>): SortingState =>
        sorting ? [{ id: sorting.attribute, desc: sorting.order === Order.Desc }] : [],
    toSorting: (state: SortingState): Sorting<string> | undefined =>
        state.length
            ? { attribute: state[0].id, order: state[0].desc ? Order.Desc : Order.Asc }
            : undefined,
};

type Props = {
    onEditClick: (property: Configuration.Property) => void;
    onSortChange: (sorting?: Sorting<string>) => void;
    properties?: Configuration.Property[];
    sorting?: Sorting<string>;
};

const columnHelper = createColumnHelper<Configuration.Property>();

export const PropertiesTable = (props: Props) => {
    const sorting: SortingState = React.useMemo(
        () => SortingUtils.toSortingState(props.sorting),
        [props.sorting],
    );

    const handleSortChange: OnChangeFn<SortingState> = React.useCallback(
        (updater) => {
            if (typeof updater === 'function') {
                props.onSortChange(SortingUtils.toSorting(updater(sorting)));
            } else {
                props.onSortChange(SortingUtils.toSorting(updater));
            }
        },
        [props, sorting],
    );

    const tableColumns = React.useMemo<ColumnDef<Configuration.Property, any>[]>(
        () => [
            columnHelper.accessor('key', {
                id: 'key',
                header: () => 'Key',
                cell: (context) => context.getValue(),
            }),
            columnHelper.accessor('name', {
                id: 'name',
                header: () => 'Stat Name',
                cell: (context) => context.getValue(),
            }),
            columnHelper.accessor('abbreviation', {
                id: 'abbreviation',
                header: () => 'Abbreviation',
                cell: (context) => context.getValue() ?? '--',
            }),
            columnHelper.accessor('type', {
                id: 'type',
                header: () => 'Type',
                cell: (context) => <span className="type-cell">{context.getValue()}</span>,
            }),
            columnHelper.accessor('category.name', {
                id: 'category',
                header: () => 'Category',
                cell: (context) => context.getValue(),
            }),
            columnHelper.accessor('enableForExtendedBoxscore', {
                id: 'ext-boxscore',
                header: () => 'Ext. Boxscore',
                cell: (context) => (context.getValue() ? 'Yes' : 'No'),
            }),
            columnHelper.accessor('description', {
                id: 'description',
                header: () => 'Description',
                cell: (context) => context.getValue() ?? '--',
            }),
            columnHelper.display({
                id: 'sources',
                header: () => 'Sources',
                cell: (context) => (
                    <Popover
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        placeholder={<span className="details-button">Details</span>}
                    >
                        {() => <PropertyPopover property={context.row.original} />}
                    </Popover>
                ),
            }),
            columnHelper.accessor('updatedAt', {
                id: 'last-modified',
                header: () => 'Last Modified',
                cell: (context) => dayjs(context.getValue()).format('MM/DD/YY hh:mm A'),
            }),
            columnHelper.display({
                id: 'edit',
                header: () => 'Edit',
                cell: (context) => (
                    <button
                        className="button-link"
                        onClick={() => props.onEditClick(context.row.original)}
                    >
                        Edit
                    </button>
                ),
            }),
        ],
        [props],
    );

    const table = useReactTable<Configuration.Property>({
        data: props.properties ?? [],
        columns: tableColumns,
        state: { sorting },
        onSortingChange: handleSortChange,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <PropertyTableContainer>
            <Table.Table>
                <Table.TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Table.TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Table.TableCell
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    className={header.column.getCanSort() ? 'sortable-cell' : ''}
                                >
                                    <div className="content">
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                        {{
                                            asc: <Icon name="ArrowDropUp" />,
                                            desc: <Icon name="ArrowDropDown" />,
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                </Table.TableCell>
                            ))}
                        </Table.TableRow>
                    ))}
                </Table.TableHead>
                <Table.TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <Table.TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <Table.TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Table.TableCell>
                            ))}
                        </Table.TableRow>
                    ))}
                </Table.TableBody>
            </Table.Table>
        </PropertyTableContainer>
    );
};
