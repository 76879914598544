import styled from '@emotion/styled';

export const PropertyFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 59rem;
    max-width: 100%;
    padding: 1.5rem 0;

    .form-title {
        margin: 0;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    .form-sections-container {
        display: grid;
        grid-template-areas:
            'details sources'
            'details categories';
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 4rem;
        grid-row-gap: 2rem;
    }

    .form-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 0.5rem;
        padding: 1rem 0.5rem;
    }
`;

export const SectionTitle = styled.h2`
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.3125rem;
`;

export const SectionDetailsContainer = styled.div`
    grid-area: details;

    ${SectionTitle} {
        margin-bottom: 2.75rem;
    }
`;

export const SectionSourcesContainer = styled.div`
    grid-area: sources;

    .table-container {
        text-align: center;
    }

    .enabled-cell {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tolerance-cell {
        color: var(--color-text-link);
        cursor: pointer;
    }
`;

export const SectionCategoriesContainer = styled.div`
    grid-area: categories;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
