import * as React from 'react';

import ControlBar from 'components/control-bar';
import { IconDecorated } from 'components/icons';
import Tooltip from 'components/primitives/tooltip';
import Tasks from 'components/tasks';
import TaskDetailModal from 'components/tasks/components/task-detail-modal';
import { Task } from 'components/tasks/hooks/useGroupTasks';
import useSystemEvent from 'components/tasks/hooks/useSystemEvent';
import useSystemEvents from 'components/tasks/hooks/useSystemEvents';
import ViewHeader from 'components/view-header';
import useDateSnapshot from 'hooks/useDateSnapshot';

import { TaskViewContainer } from './styles';

type Props = {};

const TasksView = (props: Props) => {
    const [task, setTask] = React.useState<Task | null>(null);

    const systemEventQuery = useSystemEvent(task?.pk, task?.sk);
    const systemEventsQuery = useSystemEvents();
    const updateDate = useDateSnapshot(systemEventsQuery.dataUpdatedAt);

    const handleViewTaskDetails = React.useCallback((t: Task) => {
        setTask(t);
    }, []);

    const handleCloseModal = () => {
        setTask(null);
    };

    return (
        <TaskViewContainer>
            <ViewHeader
                iconElement={
                    <IconDecorated paletteColor="green500" sizeRem={1.5} name="WysiwygRounded" />
                }
                iconFormat="inline"
                titleText="Tasks"
            >
                <ControlBar spacing="small">
                    <Tooltip title="Last time the tasks were pulled from the system">
                        <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    </Tooltip>
                    <Tooltip
                        disabled={systemEventsQuery.isFetching}
                        disableInteractive
                        title="Click to refresh the tasks"
                    >
                        <ControlBar.Button onClick={() => systemEventsQuery.refetch()}>
                            {systemEventsQuery.isFetching ? 'Refreshing...' : 'Refresh'}
                        </ControlBar.Button>
                    </Tooltip>
                </ControlBar>
            </ViewHeader>
            <Tasks
                loading={systemEventsQuery.isLoading}
                systemEvents={systemEventsQuery.data ?? []}
                onViewTaskDetails={handleViewTaskDetails}
            />
            <TaskDetailModal
                open={task !== null}
                task={task}
                onClose={handleCloseModal}
                loading={systemEventQuery.isLoading}
            />
        </TaskViewContainer>
    );
};

export default TasksView;
