import { StatisticsEntity, StatisticsTableDefinition } from '../../types/statistics';
import { TeamSection } from '../team-section';

import { TeamStatisticsTablesContainer } from './styles';
import { TeamStatisticsGridTable } from './team-statistics-grid-table';
import { TeamStatisticsListTable } from './team-statistics-list-table';

type Props = {
    tableDefinitions: StatisticsTableDefinition[];
    tableEntities: StatisticsEntity[];
    teamGlobalSrId?: string | null;
    teamName: string;
    searchValue: string;
    onIgnoreClick?: (id: string) => void;
};

export const TeamStatisticsTables = (props: Props) => {
    return (
        <TeamStatisticsTablesContainer>
            {props.tableDefinitions.map((definition) => (
                <TeamSection
                    key={definition.title}
                    teamGlobalSrId={props.teamGlobalSrId}
                    teamName={props.teamName}
                    tip={definition.tip}
                    title={definition.title}
                >
                    {definition.type === 'grid' && (
                        <TeamStatisticsGridTable
                            tableEntities={props.tableEntities}
                            tableDefinition={definition}
                            searchValue={props.searchValue}
                            onIgnoreClick={props.onIgnoreClick}
                        />
                    )}
                    {definition.type === 'list' && (
                        <TeamStatisticsListTable
                            tableDefinition={definition}
                            tableEntities={props.tableEntities}
                            teamName={props.teamName}
                            onIgnoreClick={props.onIgnoreClick}
                        />
                    )}
                </TeamSection>
            ))}
        </TeamStatisticsTablesContainer>
    );
};
