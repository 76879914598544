import { styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import Select, { selectClasses, SelectProps } from '@mui/material/Select';
import { svgIconClasses } from '@mui/material/SvgIcon';
import * as React from 'react';

type Props = SelectProps & {
    options: { label: string; value: string }[];
};

const FormSelectInternal = React.forwardRef<typeof Select, Props>((props, ref) => (
    <FormControl>
        <InputLabelInternal>{props.label}</InputLabelInternal>
        <Select {...props} ref={ref}>
            {props.options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
));

const InputLabelInternal = styled(InputLabel)`
    color: var(--color-text) !important;
`;

export const FormSelect = styled(FormSelectInternal)`
    & .${selectClasses.select}.${selectClasses.outlined} {
        box-sizing: border-box;
        height: 3rem;
        padding: 0.5rem 1rem;
        color: var(--color-text);
        line-height: 2rem;
    }

    & .${outlinedInputClasses.notchedOutline} {
        border-color: var(--blue500) !important;
        border-width: 0.0625rem !important;
    }

    & .${svgIconClasses.root} {
        color: var(--blue500);
    }
`;
