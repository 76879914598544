import { Controller, UseFormReturn } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';

import { FormRadioGroup } from '../form/form-radio-group';

import { SectionCategoriesContainer, SectionTitle } from './styles';

type Props = {
    categories: Configuration.PropertyCategory[];
    form: UseFormReturn<Configuration.PropertyRequest>;
};

export const SectionCategories = (props: Props) => {
    return (
        <SectionCategoriesContainer>
            <SectionTitle>Categories</SectionTitle>
            <div className="fields-container">
                <Controller
                    name="category"
                    control={props.form.control}
                    render={({ field }) => (
                        <FormRadioGroup
                            {...field}
                            options={props.categories.map((category) => ({
                                label: category.name,
                                value: category.key,
                            }))}
                        />
                    )}
                />
            </div>
        </SectionCategoriesContainer>
    );
};
