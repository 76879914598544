import styled from '@emotion/styled';

export const AdminSettingsViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    padding: 1rem 1.25rem 2rem;

    & > .controls-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .controls-group {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
        }
    }
`;
