import {
    FlagType,
    GamesGroupingStrategy,
    LeagueDetails,
    LeagueGroup,
    SeasonType,
    SourceId,
} from 'types';

const leaguesDetails: LeagueDetails[] = [
    {
        league: 'ncaamb',
        name: `NCAA Men's Basketball`,
        nameAbbreviation: 'NCAAMB',
        group: LeagueGroup.BASKETBALL_NCAA,
        sources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.AWAY,
            SourceId.HOME,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        supportedLiveGameSources: [SourceId.ESPN, SourceId.NCAA, SourceId.Statbroadcast],
        mappingSiteSources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.OWN,
            SourceId.OPP,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        seasons: [
            {
                seasonYear: '2023',
                seasonTypes: [
                    SeasonType.Regular,
                    SeasonType.ConferenceTournaments,
                    SeasonType.PostSeasonTournaments,
                ],
            },
            {
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.Regular,
                    SeasonType.ConferenceTournaments,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Polls,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
        ],
        teamPageDefaultDivision: 'D1',
        filters: {
            pollsWeek: false,
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            boxscoreGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'ncaawb',
        name: `NCAA Women's Basketball`,
        nameAbbreviation: 'NCAAWB',
        group: LeagueGroup.BASKETBALL_NCAA,
        sources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.AWAY,
            SourceId.HOME,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        supportedLiveGameSources: [SourceId.ESPN, SourceId.NCAA, SourceId.Statbroadcast],
        mappingSiteSources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.OWN,
            SourceId.OPP,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        seasons: [
            {
                seasonYear: '2023',
                seasonTypes: [
                    SeasonType.ConferenceTournaments,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
            {
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.Regular,
                    SeasonType.ConferenceTournaments,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Polls,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
        ],
        teamPageDefaultDivision: 'D1-W',
        filters: {
            pollsWeek: false,
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            boxscoreGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'nba',
        name: `NBA`,
        nameAbbreviation: 'NBA',
        group: LeagueGroup.BASKETBALL_PRO,
        sources: [],
        supportedLiveGameSources: [],
        mappingSiteSources: [],
        seasons: [
            {
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
        ],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            boxscoreGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'wnba',
        name: `WNBA`,
        nameAbbreviation: 'WNBA',
        group: LeagueGroup.BASKETBALL_PRO,
        sources: [],
        supportedLiveGameSources: [],
        mappingSiteSources: [],
        seasons: [
            {
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
        ],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            boxscoreGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'nbdl',
        name: `NBA G League`,
        nameAbbreviation: 'NBDL',
        group: LeagueGroup.BASKETBALL_PRO,
        sources: [],
        supportedLiveGameSources: [],
        mappingSiteSources: [],
        seasons: [
            {
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
        ],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            boxscoreGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },

    {
        league: 'nfl',
        name: `National Football League`,
        nameAbbreviation: 'NFL',
        group: LeagueGroup.FOOTBALL,
        sources: [SourceId.NFLGSIS, SourceId.TeamSites, SourceId.ESPN],
        supportedLiveGameSources: [SourceId.NFLGSIS, SourceId.ESPN],
        mappingSiteSources: [SourceId.NFLGSIS, SourceId.TeamSites, SourceId.ESPN],
        seasons: [
            {
                seasonYear: '2023',
                seasonTypes: [
                    SeasonType.PreSeason,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
            {
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.PreSeason,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [FlagType.Boxscore, FlagType.Roster, FlagType.Schedule, FlagType.Seasonal],
        filters: {
            leagueHierarchy: false,
            scheduleGrouping: GamesGroupingStrategy.WEEKLY,
            boxscoreGrouping: GamesGroupingStrategy.WEEKLY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'cfb',
        name: `NCAA Men's Football`,
        nameAbbreviation: 'CFB',
        group: LeagueGroup.FOOTBALL,
        sources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.AWAY,
            SourceId.HOME,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        supportedLiveGameSources: [SourceId.ESPN, SourceId.NCAA, SourceId.Statbroadcast],
        mappingSiteSources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.OWN,
            SourceId.OPP,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        seasons: [
            {
                seasonYear: '2022',
                seasonTypes: [SeasonType.Regular],
            },
            {
                seasonYear: '2023',
                seasonTypes: [SeasonType.Regular],
            },
            {
                isCurrent: true,
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
            FlagType.Polls,
        ],
        teamPageDefaultDivision: 'FBS',
        filters: {
            pollsWeek: true,
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.WEEKLY,
            boxscoreGrouping: GamesGroupingStrategy.WEEKLY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'nhl',
        name: `National Hockey League`,
        nameAbbreviation: 'NHL',
        group: LeagueGroup.HOCKEY,
        sources: [SourceId.NHL],
        supportedLiveGameSources: [],
        mappingSiteSources: [SourceId.NHL],
        seasons: [
            {
                isCurrent: true,
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.PreSeason,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
        ],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            boxscoreGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'ncaamh',
        name: `NCAA Men's Hockey`,
        nameAbbreviation: 'NCAAMH',
        group: LeagueGroup.HOCKEY,
        sources: [],
        supportedLiveGameSources: [],
        mappingSiteSources: [],
        seasons: [
            {
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [FlagType.Schedule, FlagType.Standings],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'mlb',
        name: `Major League Baseball`,
        nameAbbreviation: 'MLB',
        group: LeagueGroup.OTHER,
        sources: [],
        supportedLiveGameSources: [],
        mappingSiteSources: [],
        seasons: [
            {
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [FlagType.Schedule, FlagType.Standings],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
    {
        league: 'nascar-ot3',
        name: `NASCAR`,
        nameAbbreviation: 'NASCAR',
        group: LeagueGroup.OTHER,
        sources: [],
        supportedLiveGameSources: [],
        mappingSiteSources: [],
        seasons: [
            {
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [FlagType.Schedule, FlagType.Standings],
        filters: {
            leagueHierarchy: true,
            scheduleGrouping: GamesGroupingStrategy.DAILY,
            gamesGrouping: GamesGroupingStrategy.DAILY,
        },
    },
];

export default leaguesDetails;
