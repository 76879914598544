import { useQuery } from 'react-query';

import * as Configuration from 'api/types/configuration';

import { SettingsKeys } from './queries';

const getProperty = async (): Promise<Configuration.Property> => {
    return new Promise((r) => setTimeout(r, 300)).then(() => ({
        key: 'ncaamb_boxscore_player_field_goals_att',
        league: 'ncaamb',
        name: 'Field goal attempts',
        abbreviation: 'FGA',
        description: 'Number of field goal attempts',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    }));
};

export function usePropertyQuery(parameters: Configuration.GetPropertyParams) {
    return useQuery({
        queryKey: SettingsKeys.getProperty(parameters),
        queryFn: () => getProperty(),
    });
}
