import styled from '@emotion/styled';

export const SettingsButton = styled.button`
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-borderline);
    padding: 0.5rem;
    background: none;
    color: var(--color-text);
    font-weight: 900;
    font-size: 0.625rem;
    line-height: 1rem;
    text-transform: uppercase;
    cursor: pointer;
`;
