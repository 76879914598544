import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as React from 'react';

import Tooltip from 'components/primitives/tooltip';
import useDateSnapshot from 'hooks/useDateSnapshot';

import useTimeDifference from '../../hooks/useTimeDifference';

import { DiscrepancyClockLabel } from './styles';

type Props = {
    createdAt: dayjs.Dayjs;
};

dayjs.extend(relativeTime);

const DiscrepancyClock = ({ createdAt }: Props) => {
    const difference = useTimeDifference(createdAt);
    const dateSnapshot = useDateSnapshot(createdAt);

    const [differenceLabel, setDifferenceLabel] = React.useState('Just now');
    const [isMarked, setIsMarked] = React.useState(false);

    React.useEffect(() => {
        const secondDifference = Math.floor(difference / 1000);

        if (secondDifference > 45) {
            setDifferenceLabel(dayjs(createdAt).fromNow());
        }

        if (secondDifference >= 1800) {
            setIsMarked(true);
        }
    }, [createdAt, difference]);

    return (
        <Tooltip disableInteractive title={dateSnapshot}>
            <DiscrepancyClockLabel isMarked={isMarked}>{differenceLabel}</DiscrepancyClockLabel>
        </Tooltip>
    );
};

export default DiscrepancyClock;
