import { Mappings } from 'api/types/bff';

import { SourceDetails, SourceId } from '../types';

export const sourcesDetails: Record<SourceId, SourceDetails> = {
    [SourceId.NCAA]: {
        label: 'NCAA',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
    [SourceId.ESPN]: {
        label: 'ESPN',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
    [SourceId.SR]: {
        label: 'Sportradar',
        mappingTypes: [],
    },
    [SourceId.TeamSites]: {
        label: 'Team Sites',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
    [SourceId.NFLGSIS]: {
        label: 'NFL GSIS',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
    [SourceId.AWAY]: {
        label: 'Away',
        mappingTypes: [],
    },
    [SourceId.HOME]: {
        label: 'Home',
        mappingTypes: [],
    },
    [SourceId.OWN]: {
        label: 'Own site',
        mappingTypes: [Mappings.MappingType.PLAYERS],
    },
    [SourceId.OPP]: {
        label: 'Opp site',
        mappingTypes: [Mappings.MappingType.PLAYERS],
    },
    [SourceId.WMT]: {
        label: 'WMT',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
    [SourceId.Statbroadcast]: {
        label: 'Statbroadcast',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
    [SourceId.NHL]: {
        label: 'NHL',
        mappingTypes: [Mappings.MappingType.SCHEDULES, Mappings.MappingType.PLAYERS],
    },
};
