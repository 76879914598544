import * as React from 'react';

import * as Configuration from 'api/types/configuration';
import { Option } from 'types/controls';

export function usePropertyCategoryOptions(
    categories?: Configuration.PropertyCategory[],
    current?: string,
): Option<string | undefined>[] {
    return React.useMemo(
        () => [
            {
                label: 'All',
                value: undefined,
                selected: current === undefined,
            },
            ...(categories ?? []).map((category) => ({
                label: category.name,
                selected: category.key === current,
                value: category.key,
            })),
        ],

        [current, categories],
    );
}
