import * as typeRoute from 'type-route';

import * as routeDef from './route-def';

export const { RouteProvider, useRoute, routes, session } = typeRoute.createRouter(routeDef);

const adminSettingsRouteGroup = [
    routes.adminSettings,
    routes.adminSettingsProperty,
    routes.adminSettingsPropertyCreate,
];

const adminRouteGroup = [
    ...adminSettingsRouteGroup,
    routes.admin,
    routes.adminUserListing,
    routes.adminUserView,
    routes.adminTasksListing,
];

const leagueRouteGroup = [
    routes.dashboard,
    routes.rosters,
    routes.schedule,
    routes.standings,
    routes.seasonal,
    routes.polls,
];

const leagueGameBoxscoresRouteGroup = [
    routes.gameBoxscores,
    routes.gameBoxscoresGames,
    routes.gameBoxscoresGame,
];

const leagueTeamsRouteGroup = [routes.teams, routes.teamsMappings];

const leagueTeamsTeamStatisticsRouteGroup = [
    routes.teamsTeam,
    routes.teamsTeamPolls,
    routes.teamsTeamRoster,
    routes.teamsTeamSchedule,
    routes.teamsTeamStandings,
    routes.teamsTeamSeasonal,
];

const leagueTeamsTeamRouteGroup = [...leagueTeamsTeamStatisticsRouteGroup, routes.teamsTeamMapping];

const leagueTeamsFullRouteGroup = [...leagueTeamsRouteGroup, ...leagueTeamsTeamRouteGroup];

const leagueFullRouteGroup = [
    ...leagueRouteGroup,
    ...leagueGameBoxscoresRouteGroup,
    ...leagueTeamsFullRouteGroup,
];

export const groups = {
    admin: typeRoute.createGroup(adminRouteGroup),
    adminSettings: typeRoute.createGroup(adminSettingsRouteGroup),
    league: typeRoute.createGroup(leagueRouteGroup),
    leagueGameBoxscores: typeRoute.createGroup(leagueGameBoxscoresRouteGroup),
    leagueTeams: typeRoute.createGroup(leagueTeamsRouteGroup),
    leagueTeamsTeamStatistics: typeRoute.createGroup(leagueTeamsTeamStatisticsRouteGroup),
    leagueTeamsTeam: typeRoute.createGroup(leagueTeamsTeamRouteGroup),
    leagueTeamsFull: typeRoute.createGroup(leagueTeamsFullRouteGroup),
    leagueFull: typeRoute.createGroup(leagueFullRouteGroup),
};

export type RouteName = keyof typeof routes;
export type RouteInstance<TRouteName extends RouteName = RouteName> = typeRoute.Route<
    (typeof routes)[TRouteName]
>;
export type RouteParams<TRouteName extends RouteName> = RouteInstance<TRouteName>['params'];
