import InputAdornment from '@mui/material/InputAdornment';
import * as React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';
import Button from 'components/button';
import { IconDecorated } from 'components/icons';
import { Divider } from 'components/primitives/divider';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import * as Card from '../../card';
import { FormSearchField } from '../../form/form-search-field';
import { FormTextField } from '../../form/form-text-field';
import { SettingsButton } from '../../settings-button';
import { CardProps } from '../types';

import { Chip, ControlsContainer, MappingItemContainer, MappingsContainer } from './styles';

type FormArrayFieldValue = {
    input: string;
    from: string[];
    to: string;
};

type FormValue = {
    name: 'mapping';
    values: FormArrayFieldValue[];
};

export const CardMapping = (props: CardProps<Configuration.PropertyMappingStrategy>) => {
    const [searchValue, setSearchValue] = React.useState('');

    const form = useForm<FormValue>({
        defaultValues: {
            name: 'mapping',
            values: (props.strategy?.values ?? []).map((value) => ({ ...value, input: '' })),
        },
    });
    const formFieldArray = useFieldArray({
        name: 'values',
        control: form.control,
    });
    const formFields = React.useMemo(
        () =>
            formFieldArray.fields
                .map((field, index) => ({
                    field,
                    index: index,
                    display: [field.to, ...field.from]
                        .join(' ')
                        .toLocaleLowerCase()
                        .includes(searchValue.toLocaleLowerCase()),
                }))
                .filter((field) => field.display),
        [searchValue, formFieldArray.fields],
    );

    const handleMappingInsert = React.useCallback(() => {
        setSearchValue('');
        formFieldArray.prepend({ input: '', from: [], to: '' });
    }, [formFieldArray, setSearchValue]);

    const handleMappingRemoval = React.useCallback(
        (index: number) => formFieldArray.remove(index),
        [formFieldArray],
    );

    const handleMappingValueRemoval = React.useCallback(
        (index: number, value: string) => {
            const field = form.getValues().values.at(index);

            if (field) {
                formFieldArray.update(index, {
                    ...field,
                    from: field.from.filter((f) => f !== value),
                });
            }

            console.log(field);
        },
        [form, formFieldArray],
    );

    const handleMappingSourceInsert = React.useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
            const field = form.getValues().values.at(index);

            if (event.key === 'Enter' && field && field.input !== '') {
                formFieldArray.update(index, {
                    ...field,
                    input: '',
                    from: Array.from(new Set([...field.from, field.input])),
                });
            }
        },
        [form, formFieldArray],
    );

    return (
        <Card.Card size="large">
            <Card.CardHeader
                title="Edit Mapping"
                subtitle={`Source: ${sourcesDetails[props.source as SourceId]?.label}`}
            />
            <ControlsContainer>
                <SettingsButton onClick={handleMappingInsert}>+ Add new mapping</SettingsButton>
                <FormSearchField
                    name="search-value"
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search keyword"
                    value={searchValue}
                />
            </ControlsContainer>
            <Divider />
            <Card.CardContent>
                <MappingsContainer>
                    {formFields.length === 0 && <span>No mappings</span>}
                    {formFields.map(({ field, index }) => (
                        <MappingItemContainer key={field.id}>
                            <div className="controls-container">
                                <IconDecorated
                                    sizeRem={1.5}
                                    schemeColor="--color-btn-error"
                                    name={'DoNoDisturbOn'}
                                    onClick={() => handleMappingRemoval(index)}
                                />
                                <FormTextField
                                    {...form.register(`values.${index}.to` as const)}
                                    label={`Key ${index + 1}`}
                                />
                                <FormTextField
                                    {...form.register(`values.${index}.input` as const)}
                                    label="Add a value"
                                    onKeyDown={(event) => handleMappingSourceInsert(event, index)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">[Enter]</InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="mapping-values-container">
                                {field.from.map((from) => (
                                    <Chip
                                        key={from}
                                        label={from}
                                        onDelete={() => handleMappingValueRemoval(index, from)}
                                    />
                                ))}
                            </div>
                        </MappingItemContainer>
                    ))}
                </MappingsContainer>
            </Card.CardContent>
            <Divider />
            <Card.CardActions>
                {props.strategy && (
                    <Card.CardActionSecondary>
                        <Button variant="error" onClick={() => props.onSubmit(null)}>
                            Delete all
                        </Button>
                    </Card.CardActionSecondary>
                )}
                <Button variant="variant6-no-border" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button variant="variant6" onClick={form.handleSubmit(props.onSubmit)}>
                    Insert
                </Button>
            </Card.CardActions>
        </Card.Card>
    );
};
