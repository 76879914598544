import { styled } from '@mui/material';
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import Radio, { radioClasses } from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import * as React from 'react';

import { CheckboxIcon } from 'components/primitives/checkbox';

type Props = RadioGroupProps & {
    options: { label: string; value: string }[];
};

const FormRadioGroupInternal = React.forwardRef<typeof RadioGroup, Props>((props, ref) => (
    <RadioGroup {...props} ref={ref}>
        {props.options.map((option) => (
            <FormControlLabel
                value={option.value}
                control={
                    <Radio
                        disableRipple
                        checkedIcon={<CheckboxIcon checked />}
                        icon={<CheckboxIcon />}
                    />
                }
                label={option.label}
                key={option.value}
            />
        ))}
    </RadioGroup>
));

export const FormRadioGroup = styled(FormRadioGroupInternal)`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    & .${radioClasses.root} {
        padding: 0.1875rem;
    }

    & .${formControlLabelClasses.root} {
        margin-left: 0;
    }

    & .${formControlLabelClasses.label} {
        padding-left: 0.25rem;
        font-size: 0.875rem;
    }
`;
