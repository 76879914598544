import { useForm } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';
import Button from 'components/button';
import { Divider } from 'components/primitives/divider';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import * as Card from '../../card';
import { FormFields } from '../../form/form-fields';
import { FormTextField } from '../../form/form-text-field';
import { CardProps } from '../types';

export const CardRange = (props: CardProps<Configuration.PropertyRangeStrategy>) => {
    const form = useForm<Configuration.PropertyRangeStrategy>({
        defaultValues: {
            name: 'range',
            values: {
                from: props.strategy?.values.from,
                to: props.strategy?.values.to,
            },
        },
    });

    return (
        <Card.Card size="medium">
            <Card.CardHeader
                title="Range"
                subtitle={`Source: ${sourcesDetails[props.source as SourceId]?.label}`}
            />
            <Card.CardContent>
                <FormFields direction="row">
                    <FormTextField {...form.register('values.from')} label="From" type="number" />
                    <FormTextField {...form.register('values.to')} label="To" type="number" />
                </FormFields>
            </Card.CardContent>
            <Divider />
            <Card.CardActions>
                {props.strategy && (
                    <Card.CardActionSecondary>
                        <Button variant="error" onClick={() => props.onSubmit(null)}>
                            Delete all
                        </Button>
                    </Card.CardActionSecondary>
                )}
                <Button variant="variant6-no-border" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    variant="variant6"
                    onClick={form.handleSubmit((data) => props.onSubmit(data))}
                >
                    Insert
                </Button>
            </Card.CardActions>
        </Card.Card>
    );
};
