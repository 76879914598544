import styled from '@emotion/styled';

import { StatisticsPriority } from '../../types/statistics';

export const PlaceholderContainer = styled.div`
    display: inline-block;
    cursor: pointer;
`;

const StatisticsCellValue = styled.div`
    display: inline-block;
    min-width: 3.375rem;
    min-height: 1.25rem;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
`;

export const StatisticsCellCheckmarkValue = styled(StatisticsCellValue)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const StatisticsCellIgnoredValue = styled(StatisticsCellCheckmarkValue)`
    background-color: var(--color-statistics-cell-ignored-bg);
    color: var(--color-statistics-cell-ignored-text);
`;

export const StatisticsCellFlaggedValue = styled(StatisticsCellValue)`
    display: inline-grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    column-gap: 0.125rem;

    & .value:first-of-type {
        text-align: right;
    }

    & .value:last-of-type {
        text-align: left;
    }

    &[data-priority='${StatisticsPriority.LOW}'] {
        background-color: var(--color-statistics-cell-flagged-priority-low-bg);
        color: var(--color-statistics-cell-flagged-priority-low-text);
    }

    &[data-priority='${StatisticsPriority.MEDIUM}'] {
        background-color: var(--color-statistics-cell-flagged-priority-medium-bg);
        color: var(--color-statistics-cell-flagged-priority-medium-text);
    }

    &[data-priority='${StatisticsPriority.HIGH}'] {
        background-color: var(--color-statistics-cell-flagged-priority-high-bg);
        color: var(--color-statistics-cell-flagged-priority-high-text);
    }
`;

export const StatisticsCellPopoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    border: 0.0625rem solid var(--color-borderline);
    box-shadow: 0 0.125rem 0.25rem 0 var(--blue2000);
    padding: 0.75rem 1rem;
    background-color: var(--color-background);

    & > .group-list {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        & > .group > .group-item {
            display: block;
            color: var(--color-text);
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1rem;

            &:not(:first-of-type) {
                font-weight: 400;
                opacity: 0.8;
            }
        }
    }
`;
