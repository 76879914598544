import * as React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import * as Configuration from 'api/types/configuration';

import { FormCheckbox } from '../form/form-checkbox';
import { FormFields } from '../form/form-fields';
import { FormSelect } from '../form/form-select';
import { FormTextField } from '../form/form-text-field';

import { SectionDetailsContainer, SectionTitle } from './styles';

type Props = {
    property?: Configuration.Property;
    form: UseFormReturn<Configuration.PropertyRequest>;
};

export const SectionDetails = (props: Props) => {
    return (
        <SectionDetailsContainer>
            <SectionTitle>Stat Details</SectionTitle>
            <FormFields>
                <FormTextField
                    {...props.form.register('key')}
                    {...(!!props.property && {
                        disabled: true,
                        defaultValue: props.property.key,
                    })}
                    label="Key"
                />
                <FormTextField {...props.form.register('name')} label="Stat Name" />
                <FormTextField {...props.form.register('abbreviation')} label="Abbreviation" />
                <FormTextField {...props.form.register('description')} label="Description" />
                <Controller
                    name="type"
                    control={props.form.control}
                    render={({ field }) => (
                        <FormSelect
                            {...field}
                            label="Type"
                            options={[
                                { label: 'Game', value: Configuration.PropertyType.GAME },
                                { label: 'Team', value: Configuration.PropertyType.TEAM },
                                { label: 'Player', value: Configuration.PropertyType.PLAYER },
                            ]}
                        />
                    )}
                />
                <FormTextField {...props.form.register('excludeDivisions')} label="Division" />
                <FormCheckbox
                    {...props.form.register('enableForExtendedBoxscore')}
                    label={
                        <React.Fragment>
                            Enable for <i>Extended Boxscore</i>
                        </React.Fragment>
                    }
                />
            </FormFields>
        </SectionDetailsContainer>
    );
};
