import styled from '@emotion/styled';

export const StatisticsNameCellContainer = styled.div<{ fontWeight: string }>`
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;

    .icon-container {
        width: 1rem;
        height: 1rem;
    }

    .content-container {
        flex-grow: 1;
        white-space: wrap;
        font-weight: ${(props) => props.fontWeight};
    }
`;
