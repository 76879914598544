import * as React from 'react';

import useTeamCrest from 'app/hooks/useTeamCrest';
import { ReactComponent as IconShield } from 'assets/svg/icon-shield.svg';
import { Icon } from 'components/icons';
import LazyImage from 'components/lazy-image';

import { HeaderContainer, TeamSectionContainer, TipContainer } from './styles';

type Props = {
    children?: React.ReactNode;
    teamGlobalSrId?: string | null;
    teamName: string;
    tip?: React.ReactNode;
    title: string;
};

export const TeamSection = React.memo((props: Props) => {
    const teamCrest = useTeamCrest(props.teamGlobalSrId);

    return (
        <TeamSectionContainer>
            <HeaderContainer>
                <div className="image-container">
                    <LazyImage
                        className="team-logo"
                        data-testid="team-logo"
                        fallback={<IconShield className="team-icon" />}
                        src={teamCrest}
                    />
                </div>
                <div className="content-container">
                    <p className="team-name">{props.teamName}</p>
                    <p className="title">{props.title}</p>
                </div>
                {props.tip && (
                    <TipContainer>
                        <div className="tip-icon-container">
                            <Icon name="Info" />
                        </div>
                        <span className="tip-content">
                            <span className="tip-title">Tip: </span>
                            <span>{props.tip}</span>
                        </span>
                    </TipContainer>
                )}
            </HeaderContainer>
            {props.children}
        </TeamSectionContainer>
    );
});
