import { FormFieldsContainer } from './styles';

type Props = {
    direction?: 'row' | 'column';
    children: React.ReactNode;
};

export const FormFields = ({ direction = 'column', ...props }: React.PropsWithChildren<Props>) => (
    <FormFieldsContainer {...props} direction={direction} />
);
