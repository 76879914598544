import { CardHeaderContainer } from './styles';

type Props = {
    title: string;
    subtitle: string;
};

export const CardHeader = (props: Props) => (
    <CardHeaderContainer>
        <p className="subtitle">{props.subtitle}</p>
        <h2 className="title">{props.title}</h2>
    </CardHeaderContainer>
);
