import dayjs from 'dayjs';
import * as React from 'react';

import {
    StatisticsPriorityContext,
    StatisticsPriorityContextValue,
} from '../../contexts/statistics-priority';
import { StatisticsEntity, StatisticsPriority } from '../../types/statistics';

const PRIORITY_HIGH_THRESHOLD_MIN = 15;
const PRIORITY_MEDIUM_THRESHOLD_MIN = 5;

type Props = {
    children?: React.ReactNode;
    entities: StatisticsEntity[];
};

const getContextValue = (entities: StatisticsEntity[]): StatisticsPriorityContextValue => {
    const value: StatisticsPriorityContextValue = {};

    entities.forEach((entity) => {
        Object.values(entity.properties ?? {}).forEach((property) => {
            if (!property) {
                return;
            }

            const difference = dayjs().diff(property.createdAt, 'minute');

            value[property.id] =
                difference > PRIORITY_HIGH_THRESHOLD_MIN
                    ? StatisticsPriority.HIGH
                    : difference > PRIORITY_MEDIUM_THRESHOLD_MIN
                    ? StatisticsPriority.MEDIUM
                    : StatisticsPriority.LOW;
        });
    });

    return value;
};

export const StatisticsPriorityProvider = (props: Props) => {
    const [value, setValue] = React.useState(getContextValue(props.entities));

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setValue(getContextValue(props.entities));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [props.entities, setValue]);

    return <StatisticsPriorityContext.Provider value={value} {...props} />;
};
