import { StatisticsTableDefinition } from '../types/statistics';

export const TABLE_DEFINITIONS_NCAAMB_NCAAWB: StatisticsTableDefinition[] = [
    {
        title: 'Player Stats',
        tip: 'Click on flag or ignored item to see timestamp',
        type: 'grid',
        columns: [
            {
                title: 'MIN',
                name: 'Minutes',
                description: 'Number of minutes played',
                keys: { player: 'Minutes' },
            },
            {
                title: 'FGM',
                name: 'Field Goals Made',
                description: 'Number of field goals made',
                keys: { player: 'Field Goals Made', team: 'Field Goals Made' },
            },
            {
                title: 'FGA',
                name: 'Field Goal Attempts',
                description: 'Number of field goal attempts',
                keys: { player: 'Field Goal Attempts', team: 'Field Goal Attempts' },
            },
            {
                title: '3PM',
                name: 'Three Points Made',
                description: 'Number of 3-point baskets',
                keys: { player: 'Three Points Made', team: 'Three Points Made' },
            },
            {
                title: '3PA',
                name: 'Three Point Attempts',
                description: 'Number of 3-point attempts',
                keys: { player: 'Three Point Attempts', team: 'Three Point Attempts' },
            },
            {
                title: 'FTM',
                name: 'Free Throws Made',
                description: 'Number of free throws made',
                keys: { player: 'Free Throws Made', team: 'Free Throws Made' },
            },
            {
                title: 'FTA',
                name: 'Free Throw Attempts',
                description: 'Number of free throw attempts',
                keys: { player: 'Free Throw Attempts', team: 'Free Throw Attempts' },
            },
            {
                title: 'OREB',
                name: 'Offensive Rebounds',
                description: 'Number of offensive rebounds',
                keys: { player: 'Offensive Rebounds' },
            },
            {
                title: 'DREB',
                name: 'Defensive Rebounds',
                description: 'Number of defensive rebounds',
                keys: { player: 'Defensive Rebounds' },
            },
            {
                title: 'REB',
                name: 'Rebounds',
                description: 'Number of rebounds',
                keys: { player: 'Rebounds', team: 'Total Rebounds' },
            },
            {
                title: 'AST',
                name: 'Assists',
                description: 'Number of assists',
                keys: { player: 'Assists', team: 'Assists' },
            },
            {
                title: 'PF',
                name: 'Personal Fouls',
                description: 'Number of personal fouls',
                keys: { player: 'Personal Fouls', team: 'Personal Fouls' },
            },
            {
                title: 'STL',
                name: 'Steals',
                description: 'Number of steals',
                keys: { player: 'Steals', team: 'Steals' },
            },
            {
                title: 'TO',
                name: 'Turnovers',
                description: 'Number of turnovers',
                keys: { player: 'Turnovers', team: 'Total Turnovers' },
            },
            {
                title: 'BLK',
                name: 'Blocked Shots',
                description: 'Number of shots blocked',
                keys: { player: 'Blocked Shots', team: 'Blocked Shots' },
            },
            {
                title: 'PTS',
                name: 'Points',
                description: 'Number of points',
                keys: { player: 'Points', team: 'Points' },
            },
        ],
    },
    {
        title: 'Team Stats',
        type: 'list',
        rows: [
            {
                title: 'Team Offensive Rebounds',
                description: 'Number of offensive rebounds',
                key: 'Team Offensive Rebounds',
            },
            {
                title: 'Team Defensive Rebounds',
                description: 'Number of defensive rebounds',
                key: 'Team Defensive Rebounds',
            },
            {
                title: 'Second Chance Points',
                description: 'Number of second chance points',
                key: 'Second Chance Points',
            },
            {
                title: 'Fast Break Points',
                description: 'Number of fast break points',
                key: 'Fast Break Points',
            },
            {
                title: 'Points off Turnovers',
                description: 'Number of points off turnovers',
                key: 'Points off Turnovers',
            },
            {
                title: 'Points in the Paint Made',
                description: 'Number of points in the paint baskets',
                key: 'Points in the Paint Made',
            },
        ],
    },
];
