import styled from '@emotion/styled';

export const TeamSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;

    & > .image-container {
        width: 3rem;
        height: 3rem;
    }

    & > .content-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 0.24rem;
        justify-content: center;

        .team-icon {
            transform: scale(0.8);
        }

        .team-logo {
            transform: scale(1.2);
        }

        & > .team-name {
            margin: 0;
            font-size: 0.625rem;
            line-height: 0.75rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        & > .title {
            margin: 0;
            font-size: 1.125rem;
            line-height: 1.3125rem;
            font-weight: 700;
        }
    }
`;

export const TipContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem;

    .tip-icon-container {
        width: 1rem;
        height: 1rem;
    }

    .tip-content {
        font-size: 0.75rem;
        line-height: 0.875rem;
    }

    .tip-title {
        font-weight: 800;
    }
`;
