import * as React from 'react';

import { TableContext } from '../contexts/table';

import { TableCellContainer, TableCellHeadContainer } from './styles';

type Props = React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
> & {
    children?: React.ReactNode;
    textAlign?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
};

export const TableCell = React.forwardRef<HTMLTableCellElement, Props>(
    ({ textAlign = 'inherit', ...props }, ref) => {
        const table = React.useContext(TableContext);

        const TableCellComponent =
            table.variant === 'head' ? TableCellHeadContainer : TableCellContainer;

        return <TableCellComponent {...props} textAlign={textAlign} ref={ref} />;
    },
);
