import { LiveFlags } from 'api/types/live-flags';
import { IconDecorated } from 'components/icons';
import Popover from 'components/popover';

import { StatisticsEntityProperty } from '../../types/statistics';

import { StatisticsCellPopover } from './statistics-cell-popover';
import { StatisticsCellValue } from './statistics-cell-value';
import {
    PlaceholderContainer,
    StatisticsCellCheckmarkValue,
    StatisticsCellIgnoredValue,
} from './styles';

type Props = {
    entityName?: string;
    propertyName: string;
    property?: StatisticsEntityProperty;
    onIgnoreClick?: (id: string) => void;
    onUnignoreClick?: (id: string) => void;
};

export const StatisticsCell = ({ property, ...props }: Props) => {
    switch (property?.status) {
        case LiveFlags.FlagStatus.IGNORED:
            return (
                <PlaceholderContainer aria-hidden="true">
                    <Popover
                        placeholder={
                            <StatisticsCellIgnoredValue>Ignored</StatisticsCellIgnoredValue>
                        }
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        {() => <StatisticsCellPopover {...props} property={property} />}
                    </Popover>
                </PlaceholderContainer>
            );
        case LiveFlags.FlagStatus.CREATED:
            return (
                <PlaceholderContainer aria-hidden="true">
                    <Popover
                        placeholder={<StatisticsCellValue property={property} />}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        {() => <StatisticsCellPopover {...props} property={property} />}
                    </Popover>
                </PlaceholderContainer>
            );
        default:
            return (
                <StatisticsCellCheckmarkValue>
                    <IconDecorated
                        schemeColor="--color-live-game-divider"
                        sizeRem={1}
                        name="CheckRounded"
                    />
                </StatisticsCellCheckmarkValue>
            );
    }
};
