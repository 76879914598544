import { Route } from 'type-route';

import { groups, routes } from '../../app/router';

import { AdminSettingsRoute } from './settings';
import TasksView from './tasks/views/index';
import { UserList } from './users/user-list';
import { UserView } from './users/user-view';

type Props = {
    route: Route<typeof routes>;
};

const Admin = ({ route }: Props) => {
    return (
        <>
            {route.name === 'adminUserView' && <UserView params={route.params} />}
            {route.name === 'adminUserListing' && <UserList />}
            {route.name === 'adminTasksListing' && <TasksView />}
            {groups.adminSettings.has(route) && <AdminSettingsRoute route={route} />}
        </>
    );
};

export default Admin;
