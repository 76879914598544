import dayjs from 'dayjs';
import * as React from 'react';
import { useState } from 'react';
import { Route } from 'type-route';
import { useDebounce } from 'usehooks-ts';

import { Game } from 'api/types/bff';
import { LiveFlags } from 'api/types/live-flags';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import ExternalLinks from 'components/external-links';
import FlagStats from 'components/flag-stats';
import FlagSystemEvents from 'components/flag-system-events';
import SourceSelector from 'components/source-selector';
import { TextField } from 'components/text-field';
import { useChangeParams } from 'hooks/useChangeParams';
import useExternalLinks from 'hooks/useExternalLinks';
import { useGroupBy } from 'hooks/useGroupBy';
import { ContextType, ExternalSourceId, FlagType, SourceId } from 'types';

import { STATUS_LIMIT_CONFIG } from '../../constants/flag-status-limit-config';
import { useLimitOptions } from '../../hooks/useLimitOptions';
import { useLiveFlagStatusOptions } from '../../hooks/useLiveFlagStatusOptions';
import { useLiveValuesBySource } from '../../hooks/useLiveValuesBySource';
import { useStatisticsEntities } from '../../hooks/useStatisticsEntities';
import { useStatisticsTableDefinitions } from '../../hooks/useStatisticsTableDefinitions';
import useUpdateFlag from '../../hooks/useUpdateFlag';
import { ColumnLayout } from '../column-layout';
import DiscrepancyControlBar from '../discrepancy-control-bar';
import { StatisticsPriorityProvider } from '../statistics-priority-provider';
import { TeamStatisticsTables } from '../team-statistics-tables';

import { LiveGameHeaderContainer, LiveGameTableSectionContainer } from './styles';

type Props = {
    game: Game.LiveGame;
    isRefreshing: boolean;
    liveSource: ExternalSourceId;
    onRefreshClick: () => void;
    route: Route<typeof routes.gameBoxscoresGame>;
    updatedAt?: number;
};

const LiveGameTableSection = (props: Props) => {
    const leagueDetails = useLeagueDetails(props.route.params.league);
    const tableDefinitions = useStatisticsTableDefinitions(props.route.params.league);
    const entities = useStatisticsEntities(props.game);
    const entityGroups = useGroupBy(entities, (entity) => entity.qualifier);

    const flagStatusMutation = useUpdateFlag(props.route.params.id);

    const valuesBySource = useLiveValuesBySource(leagueDetails.supportedLiveGameSources);
    const externalLinksQuery = useExternalLinks(
        {
            league: props.route.params.league,
            seasonType: props.route.params.seasonType,
            seasonYear: props.route.params.seasonYear,
            id: props.route.params.id,
            flagType: FlagType.LiveBoxscore,
        },
        true,
    );

    const liveStatusOptions = useLiveFlagStatusOptions(props.route.params.liveStatus);
    const limitOptions = useLimitOptions(
        STATUS_LIMIT_CONFIG[props.route.params.liveStatus].allowedLimits,
        props.route.params.liveLimit,
    );

    const [searchValue, setSearchValue] = useState('');
    const currentSearchValue = useDebounce(searchValue, 500);

    const awayTeamName = `${props.game.awayTeam?.market} ${props.game.awayTeam?.name}`;
    const homeTeamName = `${props.game.homeTeam?.market} ${props.game.homeTeam?.name}`;

    const handleParamsChange = useChangeParams(props.route);

    const handleSourceChange = React.useCallback(
        (source: SourceId) => handleParamsChange({ liveSource: source as ExternalSourceId }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (liveStatus?: LiveFlags.FlagStatus) => {
            handleParamsChange({
                liveStatus,
                ...(liveStatus &&
                    STATUS_LIMIT_CONFIG[liveStatus].allowedLimits.length === 1 && {
                        liveLimit: undefined,
                    }),
            });
        },
        [handleParamsChange],
    );
    const handleLimitChange = React.useCallback(
        (limit: number) => handleParamsChange({ liveLimit: limit }),
        [handleParamsChange],
    );

    const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        (e) => setSearchValue(e.target.value),
        [setSearchValue],
    );

    const handleDiscrepancyIgnore = React.useCallback(
        (id: string) => {
            flagStatusMutation.mutate({
                id,
                status: LiveFlags.FlagStatus.IGNORED,
            });
        },
        [flagStatusMutation],
    );

    return (
        <LiveGameTableSectionContainer>
            <LiveGameHeaderContainer>
                <div className="controls-primary">
                    <SourceSelector
                        activeSource={props.liveSource}
                        valuesBySource={valuesBySource}
                        onSourceClick={handleSourceChange}
                    />
                    <ControlPanel.Group>
                        <ExternalLinks
                            isLoading={externalLinksQuery.isLoading}
                            linksInfo={externalLinksQuery.data ?? []}
                        />
                        <DiscrepancyControlBar
                            defaultSource={props.liveSource}
                            isRefreshing={props.isRefreshing}
                            onRefreshClick={props.onRefreshClick}
                            teams={[
                                { id: props.game.awayTeam?.srId, name: awayTeamName },
                                { id: props.game.homeTeam?.srId, name: homeTeamName },
                            ]}
                            updatedAt={dayjs(props.updatedAt)}
                        />
                        <FlagSystemEvents
                            league={props.route.params.league}
                            seasonType={props.route.params.seasonType}
                            seasonYear={props.route.params.seasonYear}
                            contextObjectId={props.route.params.id}
                            context={ContextType.LiveGame}
                        />
                    </ControlPanel.Group>
                </div>
                <FlagStats
                    flagged={props.game.flagStats.CREATED}
                    ignored={props.game.flagStats.IGNORED}
                />
                <div className="controls-secondary">
                    <Dropdown
                        label="Status"
                        options={liveStatusOptions}
                        onChange={handleStatusChange}
                    />
                    {STATUS_LIMIT_CONFIG[props.route.params.liveStatus].allowedLimits.length >
                        1 && (
                        <Dropdown
                            label="Page Size"
                            options={limitOptions}
                            onChange={handleLimitChange}
                        />
                    )}
                    <TextField
                        placeholder="Search players"
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                </div>
            </LiveGameHeaderContainer>
            {tableDefinitions.length && (
                <ColumnLayout>
                    <StatisticsPriorityProvider entities={entityGroups.groups.away ?? []}>
                        <TeamStatisticsTables
                            tableDefinitions={tableDefinitions}
                            tableEntities={entityGroups.groups.away ?? []}
                            teamGlobalSrId={props.game.awayTeam?.srGlobalId}
                            teamName={awayTeamName}
                            searchValue={currentSearchValue}
                            onIgnoreClick={handleDiscrepancyIgnore}
                        />
                    </StatisticsPriorityProvider>
                    <StatisticsPriorityProvider entities={entityGroups.groups.home ?? []}>
                        <TeamStatisticsTables
                            tableDefinitions={tableDefinitions}
                            tableEntities={entityGroups.groups.home ?? []}
                            teamGlobalSrId={props.game.homeTeam?.srGlobalId}
                            teamName={homeTeamName}
                            searchValue={currentSearchValue}
                            onIgnoreClick={handleDiscrepancyIgnore}
                        />
                    </StatisticsPriorityProvider>
                </ColumnLayout>
            )}
        </LiveGameTableSectionContainer>
    );
};

export default LiveGameTableSection;
