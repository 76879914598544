import * as React from 'react';

import { StatisticsPriorityContext } from '../../contexts/statistics-priority';
import { StatisticsEntityProperty, StatisticsPriority } from '../../types/statistics';

import { StatisticsCellFlaggedValue } from './styles';

type StatisticsCellValueInternalProps = {
    priority: StatisticsPriority;
    internalValue: string | number | null;
    externalValue: string | number | null;
};

const StatisticsCellValueInternal = React.memo((props: StatisticsCellValueInternalProps) => {
    return (
        <StatisticsCellFlaggedValue data-priority={props.priority}>
            <span className="value">{props.internalValue ?? '-'}</span>/
            <span className="value">{props.externalValue ?? '-'}</span>
        </StatisticsCellFlaggedValue>
    );
});

type Props = {
    property: StatisticsEntityProperty;
};

export const StatisticsCellValue = (props: Props) => {
    const priorityContext = React.useContext(StatisticsPriorityContext);

    return (
        <StatisticsCellValueInternal
            priority={priorityContext[props.property.id] ?? StatisticsPriority.LOW}
            internalValue={props.property.internalValue}
            externalValue={props.property.externalValue}
        />
    );
};
