import styled from '@emotion/styled';

export const TeamEmptyListContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    & > .icon-container {
        width: 1.25rem;
        height: 1.25rem;
        color: var(--green500);
    }

    & > .message {
        color: var(--color-live-game-no-flags-label);
        font-size: 0.875rem;
        line-height: 1rem;
    }
`;
