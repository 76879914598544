import { Mappings } from 'api/types/bff';

export const leagues = [
    'nfl',
    'cfb',
    'ncaafb',
    'nba',
    'wnba',
    'nbdl',
    'ncaamb',
    'ncaawb',
    'nhl',
    'ncaamh',
    'mlb',
    'nascar-ot3',
] as const;

export type LeagueType = (typeof leagues)[number];

export function isLeague(league: string): league is LeagueType {
    return leagues.includes(league as LeagueType);
}

const seasonYears = ['2022', '2023', '2024'] as const;

export type SeasonYear = (typeof seasonYears)[number];

export function isSeasonYear(seasonYear: string): seasonYear is SeasonYear {
    return seasonYears.includes(seasonYear as SeasonYear);
}

export enum SeasonType {
    PreSeason = 'PRE',
    Regular = 'REG',
    ConferenceTournaments = 'CT',
    PostSeasonTournaments = 'PST',
}

export function isSeasonType(seasonType: string): seasonType is SeasonType {
    return Object.values(SeasonType).includes(seasonType as SeasonType);
}

export enum LeagueGroup {
    FOOTBALL = 'Football',
    BASKETBALL_NCAA = 'NCAA Basketball',
    BASKETBALL_PRO = 'Pro Basketball',
    HOCKEY = 'Hockey',
    OTHER = 'Other',
}

export enum GamesGroupingStrategy {
    DAILY = 'daily',
    WEEKLY = 'weekly',
}

export type LeagueDetailsSeason = {
    isCurrent?: boolean;
    seasonYear: SeasonYear;
    seasonTypes: SeasonType[];
};

export type LeagueDetails = {
    league: LeagueType;
    name: string;
    nameAbbreviation: string;
    group: LeagueGroup;
    seasons: LeagueDetailsSeason[];
    flagTypes: FlagType[];
    teamPageDefaultDivision?: string;
    sources: ExternalSourceId[];
    supportedLiveGameSources: ExternalSourceId[];
    mappingSiteSources: ExternalSourceId[];
    filters: {
        pollsWeek?: boolean;
        leagueHierarchy: boolean;
        scheduleGrouping: GamesGroupingStrategy;
        boxscoreGrouping?: GamesGroupingStrategy;
        gamesGrouping: GamesGroupingStrategy;
    };
};

export type SourceDetails = {
    label: string;
    mappingTypes: Mappings.MappingType[];
};

export enum SourceId {
    TeamSites = 'team-page',
    SR = 'sr-api',
    NCAA = 'stats.ncaa.org',
    ESPN = 'espn.com',
    NFLGSIS = 'nflgsis.com',
    HOME = 'home',
    AWAY = 'away',
    OWN = 'own',
    OPP = 'opp',
    WMT = 'wmt',
    Statbroadcast = 'statbroadcast',
    NHL = 'nhl.com',
}

export type ExternalSourceId = Exclude<SourceId, SourceId.SR>;

export function isExternalSourceId(sourceId: unknown): sourceId is ExternalSourceId {
    return sourceId !== SourceId.SR && Object.values(SourceId).includes(sourceId as SourceId);
}

export enum FlagType {
    Boxscore = 'boxscore',
    Seasonal = 'seasonal',
    Roster = 'roster',
    Schedule = 'schedule',
    Standings = 'standings',
    Polls = 'polls',
    LiveBoxscore = 'live-boxscore',
}

export enum ContextType {
    Boxscore = 'game',
    LiveGame = 'live-game',
    Seasonal = 'seasonal',
    Roster = 'roster',
    Schedule = 'schedule',
    Standings = 'standings',
    Polls = 'polls',
    Unknown = 'unknown',
}

export function flagTypeToContextType(flagType: FlagType): ContextType {
    switch (flagType) {
        case FlagType.Boxscore:
            return ContextType.Boxscore;
        case FlagType.Seasonal:
            return ContextType.Seasonal;
        case FlagType.Roster:
            return ContextType.Roster;
        case FlagType.Schedule:
            return ContextType.Schedule;
        case FlagType.Standings:
            return ContextType.Standings;
        case FlagType.Polls:
            return ContextType.Polls;
        case FlagType.LiveBoxscore:
        default:
            return ContextType.Unknown;
    }
}
