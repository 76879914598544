import styled from '@emotion/styled';

export const CardHeaderContainer = styled.div`
    /* padding: 0 0.5rem 0.625rem 0; */
    padding: 0 0.5rem 0 0;

    .subtitle {
        margin: 0 0 0.625rem;
        color: var(--color-text);
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 0.875rem;
    }

    .title {
        margin: 0;
        color: var(--color-text);
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.3125rem;
    }
`;
