import dayjs from 'dayjs';
import * as React from 'react';

import { Game } from 'api/types/bff';
import { LiveFlags } from 'api/types/live-flags';

import { StatisticsEntity, StatisticsEntityProperty } from '../types/statistics';

export function useStatisticsEntities(game: Game.LiveGame): StatisticsEntity[] {
    return React.useMemo(() => {
        const entityMap = game.liveFlags.reduce<Record<string, StatisticsEntity>>(
            (acc, liveFlag) => {
                const entityId = liveFlag.contextSrIds[liveFlag.context];

                if (!entityId) {
                    return acc;
                }

                const property: StatisticsEntityProperty = {
                    id: liveFlag.id,
                    status: liveFlag.status,
                    internalValue: liveFlag.srValue,
                    externalValue: liveFlag.externalValue,
                    createdAt: dayjs(liveFlag.timestamp),
                };

                if (!acc[entityId]) {
                    acc[entityId] = {
                        entityId,
                        entityName:
                            (liveFlag.context === LiveFlags.Context.Team
                                ? liveFlag.teamName
                                : liveFlag.playerName) ?? entityId,
                        entityType: liveFlag.context,
                        displayName:
                            liveFlag.context === LiveFlags.Context.Team
                                ? 'TOTAL'
                                : liveFlag.playerName ?? entityId,
                        qualifier:
                            liveFlag.contextSrIds.team === game.awayTeam?.srId
                                ? ('away' as const)
                                : ('home' as const),
                        properties: {},
                        periods: [],
                    };
                }

                acc[entityId].properties[liveFlag.name] = property;

                return acc;
            },
            {},
        );

        return Object.values(entityMap);
    }, [game.awayTeam?.srId, game.liveFlags]);
}
