import * as React from 'react';

import { Discrepancy } from '../../types';
import DiscrepancyList from '../discrepancy-list';
import { TeamEmptyListMessage } from '../team-empty-list';
import { TeamSection } from '../team-section';

import { DiscrepanciesContainer } from './styles';

type Props = {
    teamName: string;
    teamGlobalSrId?: string | null;
    context: 'team' | 'player';
    discrepancyGroups: [string | null, Discrepancy[]][];
    displayGroupName: boolean;
};

const DiscrepancyListGroup = ({
    teamName,
    teamGlobalSrId,
    context,
    discrepancyGroups,
    displayGroupName,
}: Props) => {
    const isPlayerGroup = context === 'player';
    const title = isPlayerGroup ? 'Player Stats' : 'Team Stats';

    const groups = React.useMemo<[string | null, Discrepancy[]][]>(() => {
        if (!isPlayerGroup) {
            return discrepancyGroups;
        }

        return discrepancyGroups.map(([group, discrepancies]) => [
            group,
            discrepancies.sort((a, b) => (a.playerName ?? '').localeCompare(b.playerName ?? '')),
        ]);
    }, [discrepancyGroups, isPlayerGroup]);

    return (
        <TeamSection teamGlobalSrId={teamGlobalSrId} teamName={teamName} title={title}>
            {groups.length ? (
                <DiscrepanciesContainer>
                    {groups.map(([group, discrepancies]) => (
                        <DiscrepancyList
                            key={group}
                            title={group}
                            discrepancies={discrepancies}
                            displayGroupName={displayGroupName}
                        />
                    ))}
                </DiscrepanciesContainer>
            ) : (
                <TeamEmptyListMessage />
            )}
        </TeamSection>
    );
};

export default DiscrepancyListGroup;
