import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';
import MuiChip from '@mui/material/Chip';

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 0.75rem 0;
`;

export const MappingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-height: 30rem;
    padding-top: 0.375rem;
    overflow-y: auto;
`;

export const MappingItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    column-gap: 0.75rem;

    .controls-container {
        display: grid;
        grid-template-columns: auto 10.6875rem 10.6875rem;
        align-items: center;
        column-gap: inherit;
    }

    .mapping-values-container {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;
        column-gap: 0.25rem;
        row-gap: 0.25rem;
    }
`;

export const Chip = muiStyled(MuiChip)`
    background-color: var(--color-property-mapping-chip-bg);
    color: var(--color-property-mapping-chip-text);

    .MuiSvgIcon-root {
        color: var(--color-property-mapping-chip-icon);
    }
`;
