import * as React from 'react';

import { Icon } from '../../icons';

import { CheckboxContainer, CheckboxIconContainer } from './styles';

type CheckboxProps = {
    checked: boolean;
    label?: string;
    onChange: () => void;
};

const Checkbox = ({ checked, label, onChange }: CheckboxProps) => {
    return (
        <CheckboxContainer onClick={onChange}>
            <CheckboxIcon checked={checked} />
            {label && <span>{label}</span>}
        </CheckboxContainer>
    );
};

type CheckboxIconProps = {
    checked?: boolean;
};

export const CheckboxIcon = ({ checked = false }: CheckboxIconProps) => (
    <CheckboxIconContainer data-checked={checked}>
        {checked && (
            <div className="icon-container">
                <Icon name="CheckRounded" />
            </div>
        )}
    </CheckboxIconContainer>
);

export default Checkbox;
