import { useMutation, useQueryClient } from 'react-query';

import * as Configuration from 'api/types/configuration';

import { SettingsKeys } from './queries';

async function postProperty(
    request: Configuration.PropertyRequest,
): Promise<Configuration.Property> {
    return new Promise((r) => setTimeout(r, 3000)).then(() => ({
        key: 'ncaamb_boxscore_player_ejections',
        league: 'ncaamb',
        name: 'Ejections',
        abbreviation: 'EJ',
        description: 'Number of ejections',
        type: Configuration.PropertyType.PLAYER,
        category: { key: 'boxscore', name: 'Boxscore' },
        updatedAt: Date.now(),
    }));
}

export function usePropertyMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: postProperty,
        onSuccess: (response) => {
            queryClient.invalidateQueries(SettingsKeys.getProperties({ league: response.league }));
            queryClient.invalidateQueries(SettingsKeys.getProperty(response));
        },
        onError: console.error,
        retry: 2,
    });
}
