import styled from '@emotion/styled';

export const ColumnLayoutContainer = styled.div`
    display: inline-flex;
    flex-direction: column;

    & > *:not(:last-child)::after {
        content: '';
        display: block;
        margin: 3rem 0;
        border-bottom: 0.0625rem dashed var(--color-live-game-divider);
    }
`;
