import styled from '@emotion/styled';

export const TextField = styled.input`
    width: 16rem;
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    background-color: transparent;
    padding: 0.5rem;
    color: var(--color-text);
    font-size: 0.875rem;
`;
