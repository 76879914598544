import * as React from 'react';
import { Route } from 'type-route';

import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import Loading from 'components/loading';
import { usePropertyCategoriesQuery } from 'hooks/settings/usePropertyCategoriesQuery';
import { usePropertyMutation } from 'hooks/settings/usePropertyMutation';
import { usePropertyQuery } from 'hooks/settings/usePropertyQuery';

import { PropertyForm } from '../../components/property-form';

type Props = {
    route: Route<typeof routes.adminSettingsProperty>;
};

export const PropertyEditView = (props: Props) => {
    const leagueDetails = useLeagueDetails(props.route.params.league);
    const propertyCategoriesQuery = usePropertyCategoriesQuery();
    const propertyQuery = usePropertyQuery({
        league: props.route.params.league,
        key: props.route.params.key,
    });

    const propertyMutation = usePropertyMutation();

    const handleSubmit = React.useCallback(
        (property) => {
            propertyMutation.mutate(property, {
                onSuccess: () => routes.adminSettings({ league: props.route.params.league }).push(),
            });
        },
        [propertyMutation, props.route.params.league],
    );

    const handleCancelClick = React.useCallback(
        () => routes.adminSettings({ league: props.route.params.league }).push(),
        [props.route.params.league],
    );

    if (propertyQuery.isLoading || propertyCategoriesQuery.isLoading) {
        return (
            <div>
                <Loading mt={2} />
            </div>
        );
    }

    return (
        <PropertyForm
            categories={propertyCategoriesQuery.data ?? []}
            isSubmitting={propertyMutation.isLoading}
            league={props.route.params.league}
            onCancel={handleCancelClick}
            onSubmit={handleSubmit}
            property={propertyQuery.data}
            sources={leagueDetails.sources}
            title="Edit stat details"
        />
    );
};
