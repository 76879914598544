import styled from '@emotion/styled';

export const BasicButton = styled.button`
    padding: 0.5rem;
    text-align: center;
    color: var(--blue500);
    min-height: 2rem;
    border: 0.0625rem solid var(--gray500);
    border-radius: 0.3125rem;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    :disabled {
        cursor: not-allowed;
        opacity: 0.75;
    }
`;

export const DarkPrimary = styled(BasicButton)`
    border-color: var(--color-btn-dark-primary-border);
    color: var(--color-btn-dark-primary-text);
    background-color: var(--color-btn-dark-primary-bg);
`;

export const Variant5 = styled(BasicButton)`
    border-color: var(--color-btn-variant5-border);
    color: var(--color-btn-variant5-text);
    background-color: var(--color-btn-variant5-bg);
`;

export const Variant6 = styled(BasicButton)`
    border-color: var(--color-btn-variant6);
    color: var(--color-btn-variant6);
    background-color: transparent;
`;

export const Variant6NoBorder = styled(BasicButton)`
    border-color: transparent;
    color: var(--color-btn-variant6);
    background-color: transparent;
`;

export const ErrorButton = styled(BasicButton)`
    border-color: var(--color-btn-error);
    color: var(--color-btn-error);
`;
