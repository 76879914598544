import { groups, routes, useRoute } from 'app/router';
import { ReactComponent as LogoUAPI } from 'assets/svg/logo-uapi.svg';
import AccountDropdown from 'components/account-dropdown';
import ThemeSwitch from 'components/theme-switch';

import LeagueControls from './league-controls';
import { HeaderContainer, LogoContainer } from './styles';

const Header = () => {
    const route = useRoute();

    const handleLogoClick = () => {
        routes.home().push();
    };

    return (
        <HeaderContainer>
            <div className="league-settings-group">
                <LogoContainer data-testid="uapi-logo-container" onClick={handleLogoClick}>
                    <LogoUAPI className="uapi-logo" />
                    <span className="uapi-title">Sports Media Data Monitor</span>
                </LogoContainer>
                {groups.leagueFull.has(route) && <LeagueControls route={route} />}
            </div>
            <div className="preferences-settings-group">
                <ThemeSwitch />
                <AccountDropdown />
            </div>
        </HeaderContainer>
    );
};

export default Header;
