import * as React from 'react';

import { Icon } from 'components/icons';

import { TeamEmptyListContainer } from './styles';

export const TeamEmptyListMessage = React.memo(() => {
    return (
        <TeamEmptyListContainer>
            <div className="icon-container">
                <Icon name="CheckCircle" />
            </div>
            <p className="message">No flags for this team</p>
        </TeamEmptyListContainer>
    );
});
