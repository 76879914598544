import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { LiveFlags } from 'api/types/live-flags';
import { useCurrentSeason } from 'app/useCurrentSeason';
import { LeagueKeys } from 'hooks/queries';

export default function useUpdateDiscrepancyState(gameId?: string) {
    const { league } = useCurrentSeason();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (discrepancy: LiveFlags.DiscrepancyStatusUpdateRequest) =>
            client.patch(`${API.BFF_API}/${league}/games/discrepancy`, discrepancy),
        onSuccess: () => {
            if (gameId) {
                queryClient.invalidateQueries(LeagueKeys.getGamePartial(league, gameId));
                queryClient.invalidateQueries(LeagueKeys.flagStats(league, gameId));
            }
        },
    });
}
